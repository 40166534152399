import React, { useState, useEffect } from 'react';
import { db } from '../middleware/firebase';
import {
  getCountFromServer,
  collection,
  orderBy,
  limit,
  query,
  where,
} from 'firebase/firestore';

const useProductCount = (filtersProp) => {
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState([filtersProp]);
  let has_data = false;

  useEffect(() => {
    if (JSON.stringify(filtersProp) != JSON.stringify(filters)) {
      setFilters(filtersProp);
    }
  }, [filtersProp]);

  const {
    brands = [],
    grpsbi = [],
    urlTypes = [],
    size = '',
    additionalAttributes = '',
    //priceRanges = [],
    promotions = false,
    altoValor = false,
    ids = [],
  } = filters;

  const fetchCount = async () => {
    let q = query(collection(db, 'products'));

    if (urlTypes.length) {
      urlTypes.forEach((elem, index) => {
        if (elem) {
          q = query(q, where(`grpsbiObj.${index}`, '==', elem));
        }
      });
    }

    if (size) {
      q = query(q, where('grpsbiObj.3', '==', size));
    }

    if (promotions) {
      has_data = true;
      q = query(
        q,
        where('has_promotion', '==', true),
        orderBy('grpsbiStr_lvl2')
      );
    }

    if (altoValor) {
      has_data = true;
      q = query(q, where('altoValor', '==', true), orderBy('grpsbiStr_lvl2'));
    }
    if (additionalAttributes) {
      q = query(q, where('grpsbiObj.4', '==', additionalAttributes));
    }

    if (grpsbi.length && !(ids && ids.length)) {
      has_data = true;
      q = query(q, where('grpsbi', 'array-contains-any', grpsbi));
    } else if (grpsbi.length) {
      q = query(q, where('grpsbi', 'array-contains', grpsbi[0]));
    }

    if (ids && ids.length) {
      has_data = true;
      q = query(q, where('id', 'in', ids));
    }

    if (brands.length) {
      has_data = true;
      q = query(q, where('aliasd', '==', brands[0]));
    }

    if (has_data === false) return false;

    //q = query(q, limit(100));
    const countSpnapshot = await getCountFromServer(q);
    const countData = countSpnapshot.data().count;
    setCount(countData);
  };

  useEffect(() => {
    fetchCount();
  }, [filters]);

  return [count];
};

export default useProductCount;

/*
 */
