import React from "react";
import { ZoneItem } from "../../../models/branch_model";
import { RichButton } from "../../common/buttons";
import Loader from "../../common/loader";

type BranchSelectionProps = {
  branches: ZoneItem[];
  clickFun: any;
  selectBranch: any;
  active_branch: any;
  active_city: string | false;
  handleCitySelect: any;
};

const BranchSelection: React.FC<BranchSelectionProps> = ({
  branches,
  clickFun,
  selectBranch,
  active_branch,
  active_city,
  handleCitySelect,
}) => {
  const zone_branches: ZoneItem | undefined = branches.find(
    (b) => b.id === active_city
  );
  if (branches.length === 0) {
    return <Loader />;
  }
  return (
    <div className="branch_secion">
      <div className="row mb-4">
        <div className="col-md-6">
          <h5>Selecciona tu ciudad</h5>
          <select className="form-control" onChange={handleCitySelect}>
            <option value="false">Selecciona tu ciudad</option>
            {branches.map((city) => {
              return (
                <option key={`option_${city.id}`} value={city.id}>
                  {city.idzalm}{" "}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <h5 className={!active_city ? "d-none" : ""}>Selecciona una sucursal</h5>
      <div className=" btn_rich_container">
        {zone_branches &&
          zone_branches.xiams.map((branch: any) => {
            const address = branch.address.split(",").splice(0, 3).join(", ");
            return (
              <RichButton
                key={`branch_${branch.id}`}
                small={true}
                active={active_branch && branch.id == active_branch}
                capitalize
                disabled={branch.id === false || branch.id === null}
                help={branch.descrp}
                title={address}
                clickFun={selectBranch}
                value={branch.id}
              />
            );
          })}
      </div>
      <button
        onClick={clickFun}
        disabled={!active_branch}
        data-next="payment"
        className="btn btn-cart btn-block text-capitalize py-4 d-md-none fixed"
      >
        Continuar
      </button>
    </div>
  );
};

export default BranchSelection;
