import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo_mathasa _full_w.png';
import { WhatsappIcon } from '../../common/buttons';
import { LocationsModal } from '../../common/modals/modals';
import { isDesign } from '../../../middleware/common-functions';

const level_one = [
  { link: '/lista/Bano', name: 'Baño' },
  { link: '/lista/Cocina', name: 'Cocinas' },
  { link: '/lista/Iluminacion', name: 'Iluminación' },
  { link: '/lista/Mosaicos', name: 'Mosaicos' },
  { link: '/lista/Recubrimientos', name: 'Recubrimientos' },
  { link: '/lista/Tinas', name: 'Tinas' },
];
const social = [
  {
    social: 'facebook',
    icon: 'fab fa-facebook-square',
    link: 'https://www.facebook.com/grupomathasa/',
  },

  {
    social: 'instagram',
    icon: 'fab fa-instagram',
    link: 'https://www.instagram.com/mathasa_oficial',
  },
  {
    social: 'youtube',
    icon: 'fab fa-youtube',
    link: 'https://www.youtube.com/channel/UCc-evsxlYxLvHZ5xL6e5xIQ',
  },
];
const footer_items = {
  Nosotros: {
    text: [
      { link: '/info/historia', text: 'Historia' },
      { link: '/info/mision_vision', text: 'Misión Visión' },
      { link: '/info/otras_empresas', text: 'Otras Empresas' },
      { link: '/info/proveedores', text: 'Proveedores' },
      { link: '/info/responsabilidad_social', text: 'Responsabilidad Social' },
    ],
  },
  Servicios: {
    text: [
      { link: '/info/mayoreo', text: 'Departamento de Mayoreo y Construcción' },
      { link: '/info/franquicias', text: 'Franquicias' },
      { link: '/info/entregas', text: 'Entregas' },
      { link: '/info/formas_pago', text: 'Formas de Pago' },
      { link: '/info/devoluciones', text: 'Políticas de Devolución' },
      { link: '/info/garantia', text: 'Políticas de Garantía' },
    ],
  },
};
const Footer = () => {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  const [modalactive, setModalActive] = useState(false);
  const is_design = isDesign();

  const handleModal = () => {
    setModalActive((modalactive) => !modalactive);
  };
  return (
    <footer className="footer ">
      <WhatsappIcon />
      <div className="container footer-info">
        <div className="row items-cont">
          <FooterItemsBold items={level_one} />
          <FooterItems items={footer_items} />
          <div className="col-md-4 ml-auto  mb-4 mb-md-0">
            <Link to="/">
              <img src={logo} className="w-50 mb-4" alt="Logo Mathasa" />
            </Link>
            <p className="small">¿Tienes alguna duda? Llámanos!</p>
            {is_design ? <DesignBlock /> : <MTBlock />}
            <p className="mt-3">
              Estás viendo precios de nuestras sucursales en{' '}
              <b>{location ? location.city : '...'}</b>
              <button
                className="mt-2  btn btn-outline-light btn-block"
                onClick={handleModal}
              >
                ¿Deseas Cambiar de Ciudad?
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="bottom_footer">
        <div className="container">
          <div className="social">
            {social.map((item, key) => (
              <a
                key={key}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`${item.social} hover`}
              >
                <i className={item.icon}></i>
              </a>
            ))}
          </div>
          <div className="legal">
            <Link to="/info/terminosycondiciones">Términos y Condiciones</Link>
            <Link to="/info/aviso">Aviso de Privacidad</Link>
          </div>
        </div>
      </div>
      <LocationsModal active={modalactive} toggle={handleModal} />
    </footer>
  );
};

const MTBlock = () => (
  <>
    <h2 className="font-weight-light">614 4 42 90 00</h2>
    <p className="small">
      Matriz <br /> Pino 3903, Granjas, Chih
    </p>
  </>
);
const DesignBlock = () => (
  <>
    <h2 className="font-weight-light">614 980 7799</h2>
    <p className="small">
      Design Center <br /> Av. Valle Escondido No.4500 L-6, Col. Las Misiones,
      Chihuahua, Chihuahua, C.P. 31115
    </p>
  </>
);

function FooterItemsBold({ items }) {
  return (
    <div className="col-md-3 col-lg-2 small mb-4 mb-md-0">
      <ul className="d-flex flex-wrap d-md-block">
        {items.map((item, key) => (
          <li className="m-2 m-md-0 mb-sm-3" key={`bottom_navs_${key}`}>
            <h3>
              <Link to={item.link}>{item.name}</Link>
            </h3>
          </li>
        ))}
      </ul>
    </div>
  );
}
function FooterItems({ items }) {
  return (
    <React.Fragment>
      {Object.keys(items).map(function (key, index) {
        return (
          <div
            className="col-6 col-md-3 col-lg-2 small text-uppercase mb-4 mb-md-0"
            key={`ul_${key}`}
          >
            <h3>{key}</h3>
            <ul>
              <FooterItem items={items[key]} />
            </ul>
          </div>
        );
      })}
    </React.Fragment>
  );
}

function FooterItem({ items }) {
  return (
    <React.Fragment>
      {Object.keys(items).map(function (key, index) {
        let item = items[key];
        let x = 0;

        let emelent = item.map((ft_item) => {
          x++;
          return (
            <li className="my-2" key={`li_text${x}`}>
              <a href={ft_item.link}>{ft_item.text}</a>
            </li>
          );
        });
        return emelent;
      })}
    </React.Fragment>
  );
}

export default Footer;
