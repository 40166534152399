import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { AnalyticsContext } from '../context/analytics-context';

const ScrollToTop = (props) => {
  const analyticsContext = useContext(AnalyticsContext);
  const [prevLocation, setLocation] = useState(null);
  useEffect(() => {
    if (props.location.pathname !== prevLocation) {
      ///send analytics view
      analyticsContext.view(props.location.pathname);
      window.scrollTo(0, 0);
      setLocation(props.location.pathname);
    }
  }, [props, analyticsContext, prevLocation]);
  return props.children;
};

export default withRouter(ScrollToTop);
