import React, { useEffect, useState } from 'react';
import { ListBlock } from '../../common/products/productBlock';
import { db } from '../../../middleware/firebase';
import { ProductItem } from '../../../models/product_model';
import { getRegularPrice, priceIva } from '../../../middleware/iva';
import { formatNumber } from '../../../middleware/common-functions';

interface NewestProductsProps {
  is_design?: boolean;
}

const NewestProducts: React.FC<NewestProductsProps> = ({
  is_design = false,
}) => {
  const [products, setProducts] = useState<ProductItem[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const snapshot = await db.collection('best_sellers').get();

      // Sort the filtered documents by "order"
      const sortedProducts = snapshot.docs.sort((a, b) =>
        a.data().order > b.data().order ? 1 : -1
      );

      const products = sortedProducts
        .map((doc) => {
          let productData = doc.data();
          const product_for_design = !!(
            productData.m_page == 'designmathasa.mx'
          );
          if (is_design == product_for_design) {
            return productData;
          }
          return null;
        })
        .filter((productData) => productData !== null) as ProductItem[]; // filter out if null

      setProducts(products);
    };

    fetchProducts();
  }, []);

  return (
    <div className="m-auto best-sellers">
      <h1>Lo Más Vendido</h1>
      <div className="product_block_container">
        {products.map((product) => {
          product.price_num = priceIva(product.zonePrice, product.price);
          product.price = formatNumber(product.price_num);
          product.price_regular = getRegularPrice(
            product.zonePrice,
            product.price_num
          );

          return <ListBlock key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
};

export default NewestProducts;
