import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Link } from "react-router-dom";
import { db } from "../../../middleware/firebase";

import useProducts, { Product } from "../../../hooks/useProducts";
import { CartBlock } from "../products/productBlock";
import NeddedProduct from "../products/neddedProducts";
import axios from "../../../middleware/axios";
import Loader from "../loader";

import {
  getNeededAmount,
  formatNumber,
} from "../../../middleware/common-functions";

import { PtrlItem } from "../../../models/product_model";
import { CartProductItem } from "../../../models/cart_model";
import { ZoneItem } from "../../../models/branch_model";

type NeededInfo = { product_id: string; cant: number };
type LocationsModalProps = { active: boolean; toggle: any };
type SaveOrderModalProps = { active: boolean; toggle: any; saveOrder: any };
type FullLoaderProps = {
  loader?: boolean;
  text?: string;
  icon?: string | null;
};
type ModalDefaultProps = {
  children: any;
  title: string;
  show: boolean;
  toggle: any;
  actionFun: any;
  action_btn: any;
  action_txt: string;
  hideFooter: boolean;
};
type AditionalProductsProps = {
  type: "needed" | "related";
  products: Product[];
  product_amount: number;
  neddedInfo: NeededInfo[];
  product_title: string;
  updateCart: any;
};
type GotoCartProps = {
  total: number;
  toggle: any;
};

type AddProductModal = {
  active: boolean;
  toggle: any;
  neddedProducts_ids: PtrlItem[];
  product_amount: number;
  relatedTypes: string;
  product_title: string;
};

export const AddProductModal: React.FC<AddProductModal> = ({
  active,
  toggle,
  neddedProducts_ids,
  product_amount,
  relatedTypes,
  product_title,
}) => {
  const needed = getNeededInfo(neddedProducts_ids);
  const ids = !needed ? null : needed.map((item) => item.product_id);
  const [cartProducts, setCartProducts] = useState<CartProductItem[] | false>(
    false
  );

  const { result: neddedProducts } = useProducts({
    ids: ids as any,
    types: [relatedTypes],
    limit: 10,
  });

  useEffect(() => {
    const cart_product = JSON.parse(localStorage.getItem("cart") || "{}");
    if (active) {
      document.body.classList.add("modal-open");
      setCartProducts(cart_product);
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [active]);
  const sumup = () => {
    if (!cartProducts) return 0;
    const sum = cartProducts
      .map((item) => {
        return Number(item.price) * item.cant;
      })
      .reduce((a, b) => a + b, 0);

    return formatNumber(sum);
  };

  const updateCart = () => {
    const cart_product = JSON.parse(localStorage.getItem("cart") || "{}");

    setCartProducts(cart_product);
  };

  return (
    <React.Fragment>
      <div className={`custom_modal product_modal ${active && "active"}`}>
        <div className="header">
          <button className="btn text-black-50" onClick={toggle}>
            <i className="fas fa-times"></i>
          </button>
          <h3>
            {needed.length
              ? "Productos Asociados o Necesarios"
              : "Producto Agregado"}
          </h3>
        </div>
        <div className="body  m-0">
          <div className="related_products scroll_container border-right ">
            {neddedProducts ? (
              <AditionalProducts
                type={needed.length ? "needed" : "related"}
                products={neddedProducts}
                updateCart={updateCart}
                neddedInfo={needed}
                product_amount={product_amount}
                product_title={product_title}
              />
            ) : null}
          </div>
          <div className="products scroll_container xs_hidden">
            {cartProducts &&
              cartProducts.reverse().map((item) => {
                return <CartBlock key={`pop_up_${item.id}`} product={item} />;
              })}
          </div>
          <div className="foot">
            <GotoCart total={sumup()} toggle={toggle} />
          </div>
        </div>
      </div>
      <div className={`backdrop ${active && "active"}`}></div>
    </React.Fragment>
  );
};

export const LocationsModal: React.FC<LocationsModalProps> = ({
  active,
  toggle,
}) => {
  const [branches, setBranches] = useState<ZoneItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!active || branches.length > 0)
      return () => {
        console.log("No need to load");
      };
    db.collection("warehouse")
      .get()
      .then((querySnapshot) => {
        const branches_obj: ZoneItem[] = [];
        querySnapshot.forEach((doc) => {
          branches_obj.push(doc.data() as ZoneItem);
        });
        branches_obj.push({
          id: "0",
          descrp: "Nacional",
          idzalm: "Nacional",
          xiams: [],
        });
        setBranches(branches_obj);
      });
    return () => {
      console.log("Branches done");
    };
  }, [active]);

  const handleCitySelect = (ev: { currentTarget: HTMLSelectElement }) => {
    const branch_id = ev.currentTarget.value;
    const url = `scriptsCartChangeBranch`;
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const selected_branch = branches.find((item) => item.id === branch_id);
    if (typeof selected_branch === "undefined") {
      return;
    }
    const location_data = {
      city: selected_branch.idzalm,
      id: branch_id,
    };
    localStorage.setItem("location", JSON.stringify(location_data));
    const params = {
      xiaz_id: branch_id,
      user_id: user.id,
      user_type: user.isAnonymous ? "anonymous" : "user",
    };
    axios
      .post(url, { ...params })
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => console.log("err", err));
  };
  return (
    <React.Fragment>
      <div className={`custom_modal ${active && "active"}`}>
        <p className="position-fixed" style={{ left: "120%" }}>
          Embellece tu hogar con las ofertas de Mathasa, pisos de cerámica,
          porcelánicos, sanitarios, lavabos, accesorios, piedra decorativa,
          iluminación, minisplits, boilers, calentones y más
        </p>
        <div className="header">
          <button className="btn text-black-50" onClick={toggle}>
            <i className="fas fa-times"></i>
          </button>
          <h3>Bienvenido</h3>
        </div>
        <div className="body text-center m-0">
          <h5>Selecciona tu ciudad</h5>
          {branches && !loading ? (
            <select className="form-control" onChange={handleCitySelect}>
              <option value="false">Selecciona tu ciudad</option>
              {branches.map((city) => {
                return (
                  <option key={`option_${city.id}`} value={city.id}>
                    {city.descrp}{" "}
                  </option>
                );
              })}
            </select>
          ) : (
            <Loader />
          )}
        </div>
        <div className="modal_footer text-center">
          <p>
            <small>
              *Los precios de los productos pueden cambiar dependiendo de la
              sucursal
            </small>
          </p>
        </div>
      </div>
      <div className={`backdrop ${active && "active"}`}></div>
    </React.Fragment>
  );
};

export const SaveOrderModal: React.FC<SaveOrderModalProps> = ({
  active,
  toggle,
  saveOrder,
}) => {
  return (
    <React.Fragment>
      <div className={`custom_modal save_order_modal ${active && "active"}`}>
        <div className="header">
          <button className="btn text-black-50" onClick={toggle}>
            <i className="fas fa-times"></i>
          </button>
          <h3>Guardar Mi Pedido</h3>
        </div>
        <div className="body  m-0">
          <h4>¿No estás listo para ordenar?</h4>
          <p>Guarda tu pedido para después </p>
          <div className="form-group">
            <input
              type="text"
              className="form-control text-center"
              placeholder="Mejorando el Baño"
              id="quote_name"
            />

            <small className="form-text text-muted">
              Este nombre sirve para que puedas identificar tu orden más
              fácilmente
            </small>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <button className="btn btn-outline-secondary" onClick={toggle}>
            Cancelar
          </button>
          <div className="align-items-center d-flex justify-content-end">
            <div className="invalid-feedback" id="quote_validation">
              Por favor agrega un nombre para tu cotización
            </div>
            <button className="btn btn-success ml-3" onClick={saveOrder}>
              Guardar
            </button>
          </div>
        </div>
      </div>
      <div className={`backdrop ${active && "active"}`}></div>
    </React.Fragment>
  );
};

const GotoCart: React.FC<GotoCartProps> = ({ total, toggle }) => (
  <div className="goto_cart">
    <div className="total">
      <span>Total</span>
      <strong>${total}</strong>
    </div>
    <button
      onClick={toggle}
      className="btn  btn-outline-secondary btn-raised rounded btn-block"
    >
      Seguir Comprando
    </button>
    <Link to="/cart" className="btn btn-cart btn-block">
      Ir al carrito <i className="fas fa-shopping-cart"></i>
    </Link>
  </div>
);

export const FullLoader: React.FC<FullLoaderProps> = ({
  loader,
  text,
  icon,
}) => (
  <div className="full_loader">
    <div className="text-center">
      <i className={`mb-4 ${icon}`}></i>
      <p>{text}</p>
      {loader && (
        <div className="loader">
          <div className="loader_top"></div>
        </div>
      )}
    </div>
  </div>
);

export const AditionalProducts: React.FC<AditionalProductsProps> = ({
  type,
  products,
  product_amount,
  neddedInfo,
  product_title,
  updateCart,
}) => {
  const title =
    type === "needed" ? (
      <div>
        <h3 className="mb-1">
          Productos Necesarios o Recomendados para la instalación.
        </h3>
        <p>
          Para una correcta instalación o uso efectivo del{" "}
          <b>{product_title}</b>, se sugieren los siguientes productos en las
          siguientes cantidades:
        </p>
      </div>
    ) : (
      <h4>Productos Relacionados</h4>
    );
  return (
    <React.Fragment>
      {" "}
      {title}
      {type === "needed"
        ? products
            .sort((item_a, item_b) => {
              ///order from nedded amount top
              const amunt_needed_a = neddedInfo.find(
                (item_n) => item_n.product_id === item_a.id
              );
              const amunt_needed_b = neddedInfo.find(
                (item_n) => item_n.product_id === item_b.id
              );
              return amunt_needed_a &&
                amunt_needed_b &&
                amunt_needed_a.cant > amunt_needed_b.cant
                ? -1
                : 1;
            })
            .map((item) => {
              const amunt_needed = neddedInfo.find(
                (item_n) => item_n.product_id === item.id
              );
              return (
                <NeddedProduct
                  product={item}
                  type={type}
                  key={item.id}
                  updateCart={updateCart}
                  product_amount={
                    amunt_needed
                      ? getNeededAmount(amunt_needed.cant, product_amount)
                      : 1
                  }
                />
              );
            })
        : products.map((item) => (
            <NeddedProduct
              product={item}
              type={type}
              key={item.id}
              updateCart={updateCart}
              product_amount={1}
            />
          ))}
      {type === "needed" && (
        <small>
          <strong>Nota:</strong> Las cantidades sugeridas son en base las
          unidades del producto que seleccionaste o agregaste a tu carrito. Son
          rendimientos promedios reales en condiciones normales de ese producto,
          puede variar según el lugar y la persona que instala.
        </small>
      )}
    </React.Fragment>
  );
};

export function getNeededInfo(neddedProducts_ids: PtrlItem[]): NeededInfo[] {
  if (!neddedProducts_ids) return [];
  return neddedProducts_ids.map((item, key) => {
    return { product_id: item.ptrl.id, cant: item.cantid };
  });
}

export const ModalDefault: React.FC<ModalDefaultProps> = ({
  children,
  title,
  show,
  toggle,
  actionFun,
  action_btn,
  action_txt,
  hideFooter,
}) => (
  <Modal isOpen={show} toggle={toggle} className={hideFooter ? "pb-5" : ""}>
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    {hideFooter ? null : (
      <ModalFooter className="d-flex justify-content-between">
        <Button
          color="secondary"
          onClick={toggle}
          className="btn-outline-dark"
          style={{ color: "white" }}
        >
          Cancelar
        </Button>
        <Button
          color={action_btn || "primary"}
          onClick={actionFun}
          id="ModalActionBtn"
        >
          {action_txt || "Aceptar"}
        </Button>{" "}
      </ModalFooter>
    )}
  </Modal>
);
