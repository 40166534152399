import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCIkPsX8Zwj3xqfPlUqv02B6H74cFgl4IU',
  authDomain: 'mathasa-website.firebaseapp.com',
  databaseURL: 'https://mathasa-website.firebaseio.com',
  projectId: 'mathasa-website',
  storageBucket: 'mathasa-website.appspot.com',
  messagingSenderId: '233535646892',
  appId: '1:233535646892:web:f9a791f6687a7bf9f14309',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

export default firebase;
export { db };
