import React, { useState, useEffect } from 'react';
import Loader from '../../common/loader';
import { db } from '../../../middleware/firebase';
import NeddedProducts from './needed';

const FeaturesSection = ({
  product_id,
  aditional,
  ptrl,
  product_amount,
  product_title,
}) => {
  const [section, setSection] = useState(null);
  const [navs_items, setNavs_items] = useState([]);
  useEffect(() => {
    const current_nav_items = [];
    if (aditional) {
      aditional.map((item, key) => {
        const list_items = item.xipcas.map((list, key_2) => {
          if (!list || !list.value) return null;
          return (
            <tr key={`list_${key_2}`}>
              <td>{list.title ? <b>{list.title}</b> : '-'}</td>
              <td>
                {list.idcmpo === 'box_size'
                  ? renderInfo(list.value)
                  : validateLink(list.value)}
              </td>
            </tr>
          );
        });
        if (item.descrp === 'linksalasfotosyvideosdelosarticulos') return;

        const new_item = {
          tag: `desc_${key}`,
          name: item.descrp,
          cont: list_items,
        };
        current_nav_items.push(new_item);
        return true;
      });
    }
    if (ptrl && ptrl.length) {
      current_nav_items.push({
        tag: 'needed',
        name: 'Productos Necesarios',
        cont: (
          <NeddedProducts
            neddedProducts_ids={ptrl}
            product_amount={product_amount}
            product_title={product_title}
          />
        ),
      });
    }
    current_nav_items.push(
      // {
      //   tag: "shipping",
      //   name: "Envíos y Devoluciones",
      //   cont: (
      //     <p className="text-center text-muted">Política de Devoluciones</p>
      //   ),
      // },
      {
        tag: 'ratings',
        name: 'Opiniones de Clientes',
        cont: <Reviews product_id={product_id} />,
      }
    );

    setNavs_items(current_nav_items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_amount]);

  const handleSection = (ev) => {
    const set_section = ev.currentTarget.dataset.section;
    setSection(set_section);
  };

  return (
    <div className="features-section my-5">
      <ul className="nav nav-pills justify-content-around" role="tablist">
        {navs_items.map((item, key) => {
          let is_active = section === item.tag;
          if (!section && !is_active) {
            is_active = key === 0;
          }
          return (
            <li className="nav-item" key={`nav_item_${key}`}>
              <button
                className={`nav-link not_active no_user_select no_focus ${
                  is_active && 'active'
                } `}
                data-section={item.tag}
                onClick={handleSection}
              >
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="tab-content px-5">
        {navs_items.map((item, key) => {
          let is_active = section === item.tag;
          if (!section && !is_active) {
            is_active = key === 0;
          }
          return (
            <div
              className={`tab-pane fade ${is_active && 'show active'}`}
              key={item.tag}
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {section === 'ratings' ? (
                item.cont
              ) : (
                <table className="table table-striped table-hover w-100">
                  <tbody>{item.cont}</tbody>
                </table>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const renderInfo = (text) => {
  const table = text.split('|');
  return (
    <table className="table table-borderless">
      <tbody>
        {table.map((item, key) => {
          const [table_title, title_data] = item.split(':');

          return (
            <tr key={`table_data${key}`} className="bg-transparent">
              <td>{table_title}</td>
              <td>{title_data}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Reviews = ({ product_id }) => {
  const [reviews, setReviews] = useState(null);
  useEffect(() => {
    loadReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadReviews = async () => {
    const collection = db
      .collection('ratings')
      .doc(product_id)
      .collection('entries');
    const snapshot = await collection.get();
    const db_reviews = [];
    snapshot.forEach((doc) => {
      const single_review = doc.data();
      db_reviews.push(single_review);
    });
    if (db_reviews.length) {
      setReviews(db_reviews);
    } else {
      setReviews(false);
    }
  };
  if (reviews === null) {
    return <Loader />;
  }

  if (reviews === false) {
    return (
      <p className="text-center text-muted">
        Todavía no tenemos suficientes opiniones de este producto
      </p>
    );
  }

  return (
    <div className="user_reviews">
      <h4>Opiniones de Nuestros Clientes</h4>

      {reviews.map((item, key) => (
        <RatingItem key={`reviews${key}`} rating={item} />
      ))}
    </div>
  );
};

const RatingItem = ({ rating }) => (
  <div className="raiting_item mt-3">
    <div className="text-warning">
      <Stars rating_value={rating.rating} />
    </div>
    <p>{rating.opinion}</p>
  </div>
);

const Stars = ({ rating_value }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <i
        className={`${i <= rating_value ? 'fas' : 'far'} fa-star`}
        key={`user_stars_${i}`}
      ></i>
    );
  }
  return stars;
};

function validateLink(txt) {
  if (!txt) return txt;
  const is_link = txt.search('http');
  if (is_link < 0) return txt;
  ///change routes from www.mathasa.mx to cdn.matahasa.mx
  let cdn_link = txt.replace('www.mathasa.mx', 'cdn.mathasa.mx');
  cdn_link = cdn_link.replace('mathasa.mx', 'cdn.mathasa.mx');
  return (
    <a href={cdn_link} target="_blank" className="pl-0 btn btn-link m-0 py-0">
      Ver Recurso <i className="fas fa-external-link-alt small"></i>
    </a>
  );
}

export default FeaturesSection;
