import React, { useEffect, useState } from 'react';
import moment from '../../../middleware/moment';
import {
  formatNumber,
  renderDeliveryDates,
} from '../../../middleware/common-functions';
import {
  CardData,
  DeliveryTypes,
  PaymentTypes,
  Totals as TotalsType,
} from '../../../models/checkout_model';
import { getIva } from '../../../middleware/iva';
import { BranchItem } from '../../../models/branch_model';
import { UserType } from '../../../models/user_model';
import { ShippingItem, ShppingResponse } from '../../../models/cart_model';

type SuccessSectionProps = {
  totals: TotalsType;
  delivery_type: DeliveryTypes;
  active_branch?: BranchItem | null;
  delivery_address: string;
  shippingcost?: ShippingItem;
  payment_type: PaymentTypes;
  cardData?: CardData;
  pay_id?: string;
};
type OrderResumeProps = {
  delivery_type: DeliveryTypes;
  selected_branch?: BranchItem | null;
  delivery_address: string;
  shippingcost?: ShippingItem;
  payment_type: PaymentTypes;
  meses: number;
  pay_id?: string;
};

const SuccessSection: React.FC<SuccessSectionProps> = ({
  totals,
  delivery_type,
  active_branch,
  delivery_address,
  shippingcost,
  payment_type,
  cardData,
  pay_id,
}) => {
  const user: UserType = JSON.parse(localStorage.getItem('user') || '{}');
  return (
    <div className="success_section ">
      <div className="row">
        <div className="col-md-6 order-2 order-md-1">
          <div className="ticket">
            <div className="header">
              <h4>Resumen de tu Pedido</h4>
              <p>{moment().format('D MMMM YYYY, h:mm:ss a')}</p>
            </div>
            <OrderResume
              delivery_type={delivery_type}
              shippingcost={shippingcost}
              delivery_address={delivery_address}
              payment_type={payment_type}
              pay_id={pay_id}
              meses={cardData ? (cardData.months ? cardData.months : 0) : 0}
              selected_branch={active_branch}
            />
            <Totals totals={totals} />
          </div>
        </div>

        <div className="col-md-4 order-1 order-md-2">
          <h4>¡Gracias por tu compra!</h4>
          <p>
            Estamos procesando tu órden, pronto recibirás un correo con la
            confirmación de la misma. <br />
            Si no lo recibes favor de ponerte en contacto{' '}
          </p>
          {user.isAnonymous && (
            <p>
              Crea una cuenta para recibir promociones especiales y revisar el
              estatus de tus compras
            </p>
          )}
          {user.isAnonymous && (
            <a className="btn btn-block btn-outline-dark" href="/signup">
              <i className="fas fa-file-invoice-dollar mr-3"></i>Crear cuenta
            </a>
          )}
          <button
            className="btn btn-block btn-outline-dark"
            onClick={() => {
              window.print();
            }}
          >
            <i className="fas fa-print mr-3"></i> Imprimir Página
          </button>
          <a className="btn btn-block btn-outline-dark" href="/cuenta/facturas">
            <i className="fas fa-print mr-3"></i> Datos de Facturación
          </a>
          <a className="btn btn-block btn-outline-dark" href="/cuenta/pedidos">
            <i className="fas fa-file-invoice-dollar mr-3"></i>Ver mis pedidos
          </a>
          <a className="btn btn-block btn-outline-dark" href="/">
            Seguir Comprando
          </a>
        </div>
      </div>
    </div>
  );
};

const Totals = ({ totals }: { totals: TotalsType }) => {
  const real_total =
    totals.comission && totals.comission > 0
      ? totals.total + totals.comission
      : totals.total;
  const iva = getIva();
  const real_tax = real_total * iva;
  return (
    <div className="totals text-right d-flex flex-column">
      {totals.subtotal && (
        <span>Subtotal ${formatNumber(totals.subtotal)}</span>
      )}
      {totals.comission && totals.comission > 0 ? (
        <span>Comisión Pago a Meses ${formatNumber(totals.comission)}</span>
      ) : null}
      <span>IVA ${formatNumber(real_tax)}</span>
      <span>Total ${formatNumber(real_total)}</span>
    </div>
  );
};

const OrderResume: React.FC<OrderResumeProps> = ({
  delivery_type,
  selected_branch,
  delivery_address,
  shippingcost,
  payment_type,
  meses,
  pay_id,
}) => (
  <ul className="list-unstyled ticket_ul">
    <Li
      icon={
        delivery_type === 'shipping'
          ? 'fas fa-shipping-fast'
          : 'fas fa-map-marker-alt'
      }
      text={delivery_address}
      help={delivery_type === 'shipping' ? 'Dirección de Entraga' : 'Sucursal'}
    />
    <Li
      icon={
        payment_type === 'online'
          ? 'far fa-credit-card'
          : payment_type === 'rappi'
          ? 'fas fa-mobile-alt'
          : 'fas fa-money-check-alt'
      }
      text={
        payment_type === 'online' ? (
          <span>
            Pago con Tarjeta Online <br />
            Id pago: {pay_id}
          </span>
        ) : payment_type === 'rappi' ? (
          <span>
            RappiPay <br />
            Id pago: {pay_id}
          </span>
        ) : payment_type === 'coppel' ? (
          'Pago con Coppel Pay'
        ) : (
          'Transferencia Electrónica'
        )
      }
      help="Forma de Pago"
    />
    {meses > 1 && (
      <Li
        icon="far fa-calendar-check"
        text={`Pagado a ${meses} meses`}
        help="Plazos"
      />
    )}
    {shippingcost ? (
      <Li
        icon="fas fa-box-open"
        text={renderDeliveryDates(shippingcost.shipping_cost)}
        help={
          delivery_type === 'shipping'
            ? 'Fecha estimada'
            : 'Tu orden lista el día:'
        }
      />
    ) : null}
  </ul>
);

const Li = ({
  icon,
  text,
  help,
}: {
  icon: string;
  text?: any;
  help: string;
}) => (
  <li className="media">
    <i className={`${icon} fa-fw mr-3`}></i>
    <div className="media-body">
      {help && <span>{help}</span>}
      <p>{text}</p>
    </div>
  </li>
);

export default SuccessSection;
