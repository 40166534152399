import React from 'react';

import { Link } from 'react-router-dom';

type PageProps = {
  from?: 'checkout';
};

const Page404: React.FC<PageProps> = ({ from }) => {
  const redirects = [
    {
      from: '/blogmathasa/wp-content/uploads/2018/09/Gu%C3%ADa-Qu%C3%A9-Hacer-TCNA.pdf',
      to: 'https://cdn.mathasa.mx/instalacion/Guia-TCNA.pdf',
    },
    {
      from: '/blogmathasa/wp-content/uploads/2018/09/HCC_GuiaInstalacion_2018.pdf',
      to: 'https://cdn.mathasa.mx/instalacion/HCC_GuiaInstalacion_2018.pdf',
    },
    {
      from: '/blogmathasa/wp-content/uploads/2019/08/MANUAL-DEL-INSTALADOR-PERDURA-NIV1-1.pdf',
      to: 'https://cdn.mathasa.mx/instalacion/MANUAL-DEL-INSTALADOR-NIV1.pdf',
    },
  ];
  ///check if url is in redirects
  const redirect = redirects.find((i) => i.from === window.location.pathname);
  if (redirect) {
    window.location.href = redirect.to;
    return null;
  }
  return (
    <main>
      <div className=" first-container">
        <div className="page_404">
          <i className="fas fa-shower fa-4x icon"></i>
          <h3>Página no encontrada</h3>
          <p>La página o producto que buscas no existe.</p>
          {from ? (
            <a href="/" className="btn btn-dark my-5">
              Volver a Página Principal
            </a>
          ) : (
            <Link to="/" className="btn btn-dark my-5">
              Volver a Página Principal
            </Link>
          )}
          <br />
          <small className="text-secondary">404 Page not Found</small>
        </div>
      </div>
    </main>
  );
};

export default Page404;
