import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import moment from "../../../middleware/moment";

import useProduct from "../../../hooks/useProduct";
import axios from "../../../middleware/axios";
import { db } from "../../../middleware/firebase";
import Loader from "../../common/loader";
import no_img from "../../../assets/no_image.jpg";

const Reviews = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [user_rating, setUserRating] = useState(0);
  const [prev_data, setPrevData] = useState(0);
  const [doneSave, setDoneSave] = useState(false);
  const { product_id } = useParams();
  const { loading, result: product_data } = useProduct(product_id);

  useEffect(() => {
    loadReview();
  }, []);

  const loadReview = async () => {
    const reviewsRef = db
      .collection("ratings")
      .doc(product_id)
      .collection("entries")
      .doc(user.id);

    const prev_review = await reviewsRef.get();
    if (prev_review.exists) {
      const get_prev = prev_review.data();
      console.log("index.js:33 | get_prev", get_prev);
      setPrevData(get_prev);
      setUserRating(get_prev.rating);
    }
  };

  const handleRating = (ev) => {
    let value = ev.currentTarget.dataset.value;
    value = parseInt(value);
    setUserRating(value);
  };

  const saveOpinion = (ev) => {
    const opinion = document.getElementById("user_opinion").value;
    const button = ev.currentTarget;
    if (user_rating < 1) {
      return false;
    }
    button.classList.add("disabled");
    axios
      .post("scriptsProductsSaveRating", {
        user_id: user.id,
        opinion,
        product_id,
        rating: user_rating,
      })
      .then((response) => {
        console.log("index.js:22 | response", response);
        setDoneSave(true);
      })
      .catch((err) => console.log("err", err));
  };

  if (loading)
    return (
      <div className="container first-container">
        <Loader full />
      </div>
    ); ///pending loader
  return (
    <div className="product_reviews first-container bg_gray  ">
      <div className="container bg-white">
        <div className="row justify-content-between">
          {doneSave ? (
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <Jumbotron
                rating={user_rating}
                heading="Gracias por compartir tu opinión"
              />
            </div>
          ) : (
            <div className="col-md-6 rate_cloumn  ">
              <h3>Cuéntanos tu Opinión</h3>
              {prev_data && (
                <div className="alert alert-secondary">
                  <p>
                    {" "}
                    Calificaste este producto el{" "}
                    {moment(prev_data.date.toDate()).format(
                      "Do, MMMM  YYYY"
                    )}{" "}
                  </p>
                </div>
              )}
              <ProductRate
                product_id={product_data.id}
                rating={user_rating}
                clickFun={handleRating}
              />
              <ProductOpinion
                product_id={product_data.id}
                prev_opinion={prev_data.opinion}
              />
              <button className="btn btn-success" onClick={saveOpinion}>
                {prev_data ? "Editar" : "Guardar"}
              </button>
            </div>
          )}
          <div className="col-md-4 min-vh-80 pt-5">
            <h6>Estás Calificando</h6>
            <ProductResume product={product_data} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductRate = ({ rating, clickFun }) => {
  const rating_value = rating || 0; ///1 to 5
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <button
        className="btn"
        data-value={i}
        onClick={clickFun}
        key={`stars_btn_${i}`}
      >
        <i className={`${i <= rating_value ? "fas" : "far"} fa-star`}></i>
      </button>
    );
  }
  return (
    <div className="product_rate">
      <h5>Calificación</h5>
      <div className="rate_producs">{stars}</div>
    </div>
  );
};
const ProductOpinion = ({ product_id, prev_opinion }) => (
  <div className="ProductOpinion">
    <h5>Escribe tu opinión </h5>
    <small>
      Cuéntanos sobre este producto. <br /> ¿Dónde lo instalaste? ¿Es como en la
      foto? ¿Era lo que esperabas?
    </small>
    <textarea
      className="form-control"
      rows="5"
      placeholder="Opcional"
      id="user_opinion"
      defaultValue={prev_opinion}
    ></textarea>
  </div>
);

const ProductResume = ({ product }) => (
  <div className="row pt-4">
    <div className="col-6">
      <div className="images">
        <img
          src={product.images ? product.images[0] : no_img}
          alt={product.descp1}
          className="img-fluid"
        />
      </div>
    </div>
    <div className="col-6">
      <p>{product.descp1}</p>
    </div>
  </div>
);

const Jumbotron = ({ rating, heading, text }) => {
  const rating_value = rating || 0; ///1 to 5
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <i
        className={`${i <= rating_value ? "fas" : "far"} fa-star`}
        key={`stars_${i}`}
      ></i>
    );
  }
  return (
    <div className="empty_jumbo">
      <div className="text-warning mr-2">{stars}</div>
      <h3>{heading}</h3>
      <p>{text}</p>
    </div>
  );
};

export default Reviews;
