import React, { useState } from "react";
import { Link } from "react-router-dom";
import useProductTags from "../../hooks/useProductTags";

const ProductMenu = ({ active, toggleNav }) => {
  const [activeTag, setActiveTag] = useState();

  const { result: productsTags, loading, error } = useProductTags();

  if (loading) return null;

  if (error) return "error...";

  return (
    <div className={`products_menu ${active ? "d-flex" : "d-none"}`}>
      <ul>
        {Object.keys(productsTags).map((tag) => {
          return (
            <li
              key={tag}
              className={`animate_all ${activeTag === tag && "active"}`}
              data-id={tag}
              onMouseEnter={(ev) => {
                const id = ev.currentTarget.dataset.id;
                setActiveTag(id);
              }}
            >
              <Link
                to={`/lista/${replaceWords(tag)}`}
                onClick={toggleNav}
                className="d-flex justify-content-between align-items-center"
              >
                {tag} <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="menu_items">
        {activeTag &&
        Object.entries(productsTags[activeTag]).map(
          ([secondaryTag, tertiaryTags]) => (
            <div className="childs" key={`menu_2_${secondaryTag}`}>
              <Link
                to={`/lista/${replaceWords(activeTag)}/${replaceWords(
                  secondaryTag
                )}`}
                onClick={toggleNav}
              >
                {secondaryTag}
              </Link>
              <ul>
                {tertiaryTags.map((tertiaryTag) => {
                  return (
                    <li key={`menu_3_${secondaryTag}_${tertiaryTag}`}>
                      <Link
                        to={`/lista/${replaceWords(activeTag)}/${replaceWords(
                          secondaryTag
                        )}/${replaceWords(tertiaryTag)}`}
                        onClick={toggleNav}
                      >
                        {tertiaryTag}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        )}
      </div>
    </div>
  );
};

function replaceWords(word) {
  word = word.split(" ").join("-");
  word = encodeURIComponent(word);
  return word.replace("Ba%C3%B1o", "Bano");
}

export default ProductMenu;
