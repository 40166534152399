import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../middleware/common-functions';
import { ProductXiuds, FromType } from '../../../models/product_model';
import { CantInput } from './productBlock';

type AddProductProps = {
  cant_hidd?: boolean;
  promptAdd?: any;
  block?: boolean;
  amount?: number;
  product_id: string;
  surface?: ProductXiuds[];
  added?: boolean;
  price: string;
  suggest_amount?: number;
  badge?: boolean;
  from?: FromType;
  onUpdate?: any;
};

type SurfaceDataProps = {
  surface_data: ProductXiuds[];
  price: string;
  badge: boolean;
};

type AddButtonProps = {
  promptAdd: any;
  block?: boolean;
  added?: boolean;
  product_id: string;
};

const AddProduct: React.FC<AddProductProps> = ({
  cant_hidd,
  promptAdd,
  block,
  amount,
  product_id,
  surface,
  added,
  price,
  suggest_amount,
  badge,
  from,
  onUpdate,
}) => {
  const [boxQty, setBoxQty] = useState(1);
  const [areaQty, setAreaQty] = useState('1');
  const [surfaceM2, setsurfaceM2] = useState<ProductXiuds | null>(null);

  useEffect(() => {
    if (surface) {
      const s: any = surface.find((i) => i.idunmd === 'mt2');
      if (s) {
        setAreaQty(formatNumber(s.fconvs, true));
        setsurfaceM2(s);
      }
    }
  }, [surface]);

  useEffect(() => {
    if (suggest_amount) {
      setBoxQty(suggest_amount);
    }
  }, [suggest_amount]);

  const handleBoxQty = (ev: { currentTarget: HTMLButtonElement }) => {
    const input_product_id = ev.currentTarget.dataset.product;
    const type = ev.currentTarget.dataset.type;
    let value;
    if (type === 'input') {
      value = ev.currentTarget.value;
    } else {
      const inputEl = document.getElementById(
        `cant_input_${input_product_id}`
      ) as HTMLInputElement;
      value = parseInt(inputEl!.value);
      if (type === 'btn_plus') {
        value++;
      } else {
        value--;
      }
      if (Number(value) < 1 || isNaN(value)) {
        value = 1;
      }
    }

    if (!isNaN(Number(value))) {
      if (surfaceM2) {
        const newValue = formatNumber(Number(value) * surfaceM2.fconvs, true);
        setAreaQty(newValue);
      }
      setBoxQty(Number(value));
    }

    if (onUpdate) {
      onUpdate(value);
    }
  };

  const handleAreaQty = (ev: { currentTarget: HTMLButtonElement }) => {
    const type = ev.currentTarget.dataset.type;
    let value;

    if (type === 'input') {
      value = ev.currentTarget.value;
      if (/^[^.]+?\.$/.test(value) || !isNaN(Number(value))) {
        setAreaQty(value);
      } else {
        return;
      }
    } else {
      value = areaQty;

      if (type == 'btn_plus') {
        value = Math.ceil(Number(value) + 1);
      } else {
        value = Math.ceil(Number(value) - 1);
      }
      if (Number(value) < 1 || isNaN(value)) {
        value = 1;
      }
      setAreaQty(String(value));
    }

    if (surfaceM2) {
      const newValue = Number(value) / surfaceM2.fconvs;
      setBoxQty(Math.ceil(newValue));
    }
  };

  if (price === '' || price === '0') {
    return (
      <span>
        No disponible <br />
      </span>
    );
  }
  return (
    <div className="add_product">
      {surface && (
        <SurfaceData
          surface_data={surface}
          price={price.replace(',', '')}
          badge={badge || false}
        />
      )}
      <div className="row">
        <div className={`${block ? 'col-md-12' : 'col-md-6'}`}>
          <div className={surface && 'd-flex align-items-center'}>
            {!cant_hidd && <label className="mr-2">Cantidad: </label>}
            {promptAdd && (
              <CantInput
                refTag={amount}
                cant={boxQty}
                changeFun={handleBoxQty}
                from={from}
                product_id={product_id}
              />
            )}
            {promptAdd && surfaceM2 && !cant_hidd && (
              <>
                <label className="mr-2">
                  M<sup>2</sup>:{' '}
                </label>
                <CantInput cant={areaQty} changeFun={handleAreaQty} />
              </>
            )}
          </div>
          {surface && promptAdd ? getSurface(surface, boxQty) : null}
        </div>

        <div className={`mt-3 ${block ? 'col-md-12' : 'col-md-6'}`}>
          {promptAdd ? (
            <AddButton
              promptAdd={promptAdd}
              block={block}
              added={added}
              product_id={product_id}
            />
          ) : (
            <Link
              to={`/product/${product_id}`}
              className={`btn  xs_block ${block && 'btn-block'} ${
                added ? 'btn-light' : 'btn-cart'
              }`}
            >
              Ver Más
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const AddButton: React.FC<AddButtonProps> = ({
  promptAdd,
  block,
  added,
  product_id,
}) => (
  <button
    className={`btn  xs_block ${block && 'btn-block'} ${
      added ? 'btn-light' : 'btn-cart'
    }`}
    onClick={added ? null : promptAdd}
    data-product_id={product_id}
  >
    {added ? (
      <React.Fragment>
        Agregado <i className="fas fa-check ml-1"></i>
      </React.Fragment>
    ) : (
      <React.Fragment>
        Agregar <i className="fas fa-cart-plus ml-1"></i>
      </React.Fragment>
    )}
  </button>
);

function getSurface(data: ProductXiuds[], amount: number | string) {
  amount = Number(amount);
  ////data comes from product.xiuds
  const surface = data.find((i) => {
    return i.idunmd === 'mt2';
  });
  ///run only on mt2 values
  if (!surface) {
    return false;
  }
  let surface_area;
  if (amount) {
    surface_area = (
      <span className="meters">
        {formatNumber(surface.fconvs * amount, true)}m<sup>2</sup>
      </span>
    );
  } else if (amount === 0) {
    surface_area = '-';
  } else {
    surface_area = (
      <span className="meters">
        {' '}
        {formatNumber(surface.fconvs, true)}m<sup>2</sup>{' '}
      </span>
    );
  }
  return (
    <span className="surface_info">
      <span className="amount_data">
        {' '}
        <i className="far fa-lightbulb"></i> Con{' '}
        {amount ? formatNumber(amount, false) : 1} caja
        {amount > 1 ? 's ' : ' '}
      </span>
      cubres <b>{surface_area}</b>
    </span>
  );
  return surface_area;
}

const SurfaceData: React.FC<SurfaceDataProps> = ({
  surface_data,
  price,
  badge,
}) => {
  const surface = surface_data.find((i) => {
    return i.idunmd === 'mt2';
  });
  ///run only on mt2 values
  if (!surface) {
    return null;
  }
  return (
    <p className="surface_data">
      <span className={`${badge && 'badge badge_main bold'}`}>
        {' '}
        ${formatNumber(Number(price) / surface.fconvs, true)}
        <span className="meters">
          /m<sup>2</sup>
        </span>{' '}
      </span>
      <span className={`${badge && 'badge badge_main'}`}>
        {' '}
        {surface.fconvs}
        <span className="meters">
          m<sup>2</sup> /caja
        </span>
      </span>
    </p>
  );
};

export default AddProduct;
