export const legal_address = {
  regrfc: {
    name: "RFC",
    validate: true,
    default: "XAXX010101000",
    value: null,
    col: "col-md-6",
  },
  nombre: {
    name: "Nombre Fiscal",
    validate: true,
    default: "",
    value: null,
    col: "col-md-6",
  },
  dcalle: {
    name: "Calle",
    validate: true,
    default: "Calle",
    value: null,
    col: "col-md-6",
  },
  numext: {
    name: "Número",
    validate: true,
    default: "1",
    value: null,
    col: "col-md-3",
  },
  numint: {
    name: "Número Interior",
    value: null,
    col: "col-md-3",
  },
  dcolon: {
    name: "Colonia",
    validate: true,
    default: "Colonia",
    value: null,
    col: "col-md-6",
  },
  dcidad: {
    name: "Ciudad",
    validate: true,
    default: "",
    value: null,
    col: "col-md-6",
  },
  ddeleg: {
    name: "Municipio",
    validate: true,
    default: "Delegación",
    value: null,
    col: "col-md-6",
  },
  destdo: {
    name: "Estado",
    validate: true,
    default: "",
    value: null,
    col: "col-md-4",
  },
  dcpost: {
    name: "Código Postal",
    validate: true,
    default: "12345",
    value: null,
    col: "col-md-2",
  },
};
