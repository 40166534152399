import { useAsync } from 'react-async-hook';
import firebase from 'firebase/compat/app';
import { db } from '../middleware/firebase';

type Filters = {
  family: {
    title: 'Categoría';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
  type: {
    title: 'Tipo';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
  brands: {
    title: 'Marca';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
  size: {
    title: 'Tamaño';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
  others: {
    title: 'Atributo/Calidad';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
  prices: {
    title: 'Precio';
    type: 'checkbox';
    elements: string[];
    selected: string[];
  };
};

export function getFilterData(
  families: string[] = [],
  types: string[] = [],
  brands: string[] = [],
  sizes: string[] = [],
  others: string[] = [],
  prices: string[] = []
): Filters {
  return {
    family: {
      title: 'Categoría',
      type: 'checkbox',
      elements: Array.from(new Set(families)).filter(Boolean).sort() || [],
      selected: [],
    },
    type: {
      title: 'Tipo',
      type: 'checkbox',
      elements: Array.from(new Set(types)).filter(Boolean).sort() || [],
      selected: [],
    },
    brands: {
      title: 'Marca',
      type: 'checkbox',
      elements: Array.from(new Set(brands)).filter(Boolean).sort() || [],
      selected: [],
    },
    size: {
      title: 'Tamaño',
      type: 'checkbox',
      elements: Array.from(new Set(sizes)).filter(Boolean).sort() || [],
      selected: [],
    },
    others: {
      title: 'Atributo/Calidad',
      type: 'checkbox',
      elements: Array.from(new Set(others)).filter(Boolean).sort() || [],
      selected: [],
    },
    prices: {
      title: 'Precio',
      type: 'checkbox',
      elements: prices || [],
      selected: [],
    },
  };
}

export async function getFilters(
  urlTypes: [string?, string?, string?],
  query?: boolean
): Promise<Filters> {
  let productTags = db.collection(
    'product-tags'
  ) as any as firebase.firestore.Query<{
    families: string[];
    types: string[];
    brands: string[];
    sizes: string[];
    others: string[];
    prices: number[];
  }>;
  const [primaryType, secondaryType, tertiaryType] = urlTypes;
  if (primaryType)
    productTags = productTags.where('primaryType', '==', primaryType);
  if (secondaryType)
    productTags = productTags.where('secondaryType', '==', secondaryType);
  if (tertiaryType)
    productTags = productTags.where('tertiaryType', '==', tertiaryType);
  const aggFamilies: string[] = [];
  const aggTypes: string[] = [];
  const aggBrands: string[] = [];
  const aggSizes: string[] = [];
  const aggOthers: string[] = [];
  const aggPrices: number[] = [];
  if (!query) return getFilterData();
  (await productTags.get()).docs
    .map((x) => x.data())
    .map(({ families, types, brands, sizes, others, prices }) => {
      aggFamilies.push(...families);
      aggTypes.push(...types);
      aggBrands.push(...brands);
      aggSizes.push(...sizes);
      aggOthers.push(...others);
      aggPrices.push(...prices);
    });
  return getFilterData(
    aggFamilies,
    aggTypes,
    aggBrands,
    aggSizes,
    aggOthers,
    getPriceRanges(aggPrices)
  );
}

export default function useFilters(
  urlTypes: [string?, string?, string?],
  query: boolean = true
) {
  return useAsync(() => getFilters(urlTypes, query), [...urlTypes, query]);
}

function getPriceRanges(prices: number[]): string[] {
  if (prices.length === 0) {
    throw new Error('The prices array is empty.');
  }

  const sortedPrices = [...prices].sort((a, b) => a - b);
  const len = sortedPrices.length;

  const quantiles = [0.2, 0.35, 0.5, 0.65, 0.8];
  const ranges: string[] = [];

  for (let i = 0; i < quantiles.length; i++) {
    const lowerIndex = Math.floor(len * (i === 0 ? 0 : quantiles[i - 1]));
    const upperIndex = Math.floor(len * quantiles[i]);

    const lowerValue = roundToNearest(sortedPrices[lowerIndex], 50);
    const upperValue = roundToNearest(sortedPrices[upperIndex], 50);

    if (i === 0) {
      ranges.push(`Menor a $${upperValue}`);
    } else if (i === quantiles.length - 1) {
      ranges.push(`Mayor a $${lowerValue}`);
    } else {
      ranges.push(`$${lowerValue} - $${upperValue - 1}`);
    }
  }

  return ranges;
}

function roundToNearest(num: number, step: number): number {
  return Math.round(num / step) * step;
}

export function getPriceRangesFromPriceString(
  prices: string[]
): [number?, number?][] {
  const ranges: [number?, number?][] = [];
  prices.forEach((price) => {
    const [num1, num2] = findNumbersInString(price);
    if (price.includes('Menor')) {
      ranges.push([undefined, num1]);
    } else if (price.includes('Mayor')) {
      ranges.push([num1, undefined]);
    } else {
      ranges.push([num1, num2]);
    }
  });
  return ranges;
}

function findNumbersInString(str: string): number[] {
  return (str.match(/\d+/g) || ([] as string[])).map((x) => Number(x));
}
