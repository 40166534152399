import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';

import ProductMenu from './productsMenu';
const Nav = () => {
  const [productActive, productSetActive] = useState(false);
  const [conoceActive, conoceSetActive] = useState(false);
  const authContext = useContext(AuthContext);

  const toggleProducts = () => {
    const toggle = !productActive;
    productSetActive(toggle);
  };

  const closeAllMenus = () => {
    productSetActive(false);
    conoceSetActive(false);
  };

  return (
    <React.Fragment>
      <div
        className={`backdrop_nav ${
          (productActive && 'active') || (conoceActive && 'active')
        }`}
        onClick={closeAllMenus}
      ></div>
      <nav className="navbar navbar_items py-0 w-100 xs_hidden">
        <Link
          to="/promociones"
          // id={item.id}
          className={`navbar-brand alto_valor`}
        >
          Promociones
          <i className={`ml-1 fas fa-tag`}></i>
        </Link>
        <Link
          to="/lista/Pisos"
          // id={item.id}
          className={`navbar-brand`}
        >
          Pisos
        </Link>
        <Link
          to="/lista/Boilers"
          // id={item.id}
          className={`navbar-brand`}
        >
          Boilers
        </Link>
        <Link
          to="/lista/Aires-y-minisplits"
          // id={item.id}
          className={`navbar-brand`}
        >
          Aires y Minisplits
        </Link>
        <button
          className="btn text-capitalize p-0 navbar-brand"
          onClick={toggleProducts}
        >
          Todos
        </button>
        <Link to="/alto_valor/Pisos" id="alto_valor" className={`navbar-brand`}>
          Alto Diseño
        </Link>
        <Link
          to="/visualiza"
          // id={item.id}
          className={`navbar-brand`}
        >
          Visualizador
        </Link>
        {authContext.isAuth ? (
          <Link
            className="btn text-capitalize p-0 navbar-brand ml-auto"
            to="/cuenta/my-cuenta"
          >
            <i className="far fa-user-circle"></i>{' '}
            {authContext.data.displayName}
          </Link>
        ) : (
          <Link
            className="btn text-capitalize p-0 navbar-brand ml-auto"
            to="/login"
          >
            <i className="far fa-user-circle"></i> Iniciar Sesión
          </Link>
        )}
        <ProductMenu active={productActive} toggleNav={toggleProducts} />
      </nav>
    </React.Fragment>
  );
};

export default Nav;
