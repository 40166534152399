import React from 'react';
import coppelpay_logo from '../../../assets/coppelpay_logo.png';
import atrato_logo from '../../../assets/atrato.png';
import paypal_logo from '../../../assets/paypal_logo.png';
import visa_logo from '../../../assets/visa_logo.png';
import mastercard_logo from '../../../assets/mastercard_logo.png';

const Totals = ({
  total,
  handleModal,
  cuponApply,
  promotion,
  removePromoCode,
  erros,
}) => (
  <div className="cart_totals">
    <div className="totals">
      <div>
        <h5>Total del Pedido</h5>
        <h5 className="total">${total.total}</h5>
      </div>
      {promotion && <RenderPromo promotion={promotion} total={total} />}
    </div>
    <div className="ctas">
      {' '}
      <Cupons
        cuponApply={cuponApply}
        cuponcode={promotion ? promotion.code : ''}
        removePromoCode={removePromoCode}
        erros={erros}
      />
      <a
        className="btn btn-block   btn-cart checkout_btn material-shadows"
        href="/checkout"
      >
        Continuar a Pago Seguro
      </a>
      <div className="payment_icons">
        <div className="icons">
          <img src={atrato_logo} alt="Paga con Atrato" />
          <img src={coppelpay_logo} alt="Paga con CoppelPay" />

          <img src={paypal_logo} alt="Paga con CoppelPay" />
          <img src={visa_logo} alt="Paga con CoppelPay" />
          <img src={mastercard_logo} alt="Paga con CoppelPay" />
        </div>
      </div>
      <hr />
      <button
        className="btn btn-block py-3 text-capitalize btn-outline-cart"
        onClick={handleModal}
      >
        <i className="far fa-save mr-2"></i> Guardar mi Pedido
      </button>
    </div>
  </div>
);

const Cupons = ({ cuponApply, cuponcode, removePromoCode, erros }) => (
  <div className="cupon_section">
    {cuponcode ? (
      <div className="promotions mb-3">
        <p>
          Código Aplicado{' '}
          <b>
            {cuponcode} <i className="fas fa-tag"></i>
          </b>
        </p>
        <div className="input-group-append">
          <button
            className="btn-outline-danger ml-3 btn-xs border-0"
            onClick={removePromoCode}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    ) : (
      <div>
        {erros && (
          <div className="alert alert-danger" id="alert-shake">
            <i className="fas fa-exclamation-circle mr-2"></i>
            {erros}
          </div>
        )}
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control text-uppercase"
            placeholder="Código Promocional"
            aria-label="Código Promocional"
            id="cupon_code"
          />
          <div className="input-group-append">
            <button className="btn btn-light border" onClick={cuponApply}>
              Aplicar
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
);

function renderPromotion(promotion) {
  switch (promotion.type) {
    case 'percentage_all':
      return `${promotion.value * 100}% de descuento`;
    case 'percentage':
      return `${promotion.value * 100}% de descuento en productos selecionados`;
    case 'amount':
      const discountValue = parseFloat(promotion.value).toFixed(2);
      return `$${discountValue} de descuento en productos selecionados`;
    default:
      return null;
  }
}

const RenderPromo = ({ promotion, total }) => (
  <div className="promotions_totals">
    <h6 className="promotion_value">- ${total.discount}</h6>
    <h6 className="total_discounted">{renderPromotion(promotion)}</h6>
  </div>
);

export default Totals;
