import React from "react";
import Iframe from "react-iframe";

const Visualizer = () => (
  <main className="first-container">
    <Iframe
      url="https://cys.dcspg.com/mathasa-vrd"
      width="100%"
      height="1500px"
      frameBorder="0"
      display="initial"
      position="relative"
    />
  </main>
);

export default Visualizer;
