import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import moment from "../../../middleware/moment";

import { db } from "../../../middleware/firebase";
import { statusTxt, formatNumber } from "../../../middleware/common-functions";
import Loader from "../../common/loader";
import EmptyJumbotron from "../../common/emptyJumbotron";
import Order from "./order";

const Orders = ({ router, type = "orders" }) => {
  const [orders, setOrders] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getOrders();
  }, []);
  const getOrders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    db.collection(type)
      .doc(user.id)
      .collection(type)
      .orderBy("timestamp", "desc")
      .get()
      .then(function (querySnapshot) {
        const orders_obj = {};
        let x = 1;
        querySnapshot.forEach(function (doc) {
          const oreder_item = doc.data();
          if (oreder_item.is_fake) {
            return;
          }
          orders_obj[doc.id] = oreder_item;
          x++;
        });
        if (x > 1) {
          setOrders(orders_obj);
          const url = router.location.pathname.split("/");
          if (url.length >= 4) {
            const order_id = url[3];
            const selected_oder = orders_obj[order_id];
            setSelectedOrder(selected_oder);
          }
        } else {
          setOrders(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error getting documents: ", error);
      });
  };

  const selectOrder = (ev) => {
    const order_id = ev.currentTarget.dataset.order_id;
    const selected_oder = orders[order_id];
    router.history.push(`/cuenta/pedidos/${order_id}`);
    setSelectedOrder(selected_oder);
  };

  const deSelection = () => {
    router.history.push(`/cuenta/pedidos`);
    setLoading(true);
    setOrders(false);
    setSelectedOrder(null);
  };

  return (
    <div className="my_orders">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {loading ? (
            <Loader />
          ) : orders ? (
            selectedOrder ? (
              <Order
                order_data={selectedOrder}
                deSelection={deSelection}
                type={type}
              />
            ) : (
              <OrdersTable data={orders} clickFun={selectOrder} />
            )
          ) : (
            <EmptyJumbotron
              icon="fas fa-truck-loading"
              heading="Sin pedidos"
              text="No has hecho ningún pedido con nosotros"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrdersTable = ({ data, clickFun }) => {
  if (isMobile) {
    return (
      <div className="list-group">
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <button
              onClick={clickFun}
              className="list-group-item list-group-item-action "
              key={key}
              data-order_id={key}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">${formatNumber(item.totals.total)}</h5>
                <small>#0000{index + 1}</small>
              </div>
              <p className="mb-1">{item.products_data.desc}</p>
              <small>
                {statusTxt(item.status)} |{" "}
                {moment(item.timestamp.toDate()).format("MMM DD ")}
              </small>
            </button>
          );
        })}
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover hover xs_hidden">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Productos</th>
          <th scope="col">Total</th>
          <th scope="col">Fecha</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <tr key={key} onClick={clickFun} data-order_id={key}>
              <th scope="row">{item.api_id ? `#${item.api_id}` : "-"}</th>
              <td>{item.products_data.desc}</td>
              <td>${formatNumber(item.totals.total)}</td>
              <td>{moment(item.timestamp.toDate()).format("MMM DD ")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Orders;
