import React, { useState, useEffect } from 'react';
import { LocationsModal } from '../common/modals/modals';
import { isDesign } from '../../middleware/common-functions';

const items = [
  // { link: "/facturacion", name: "Facturación Electronica" },
  { link: '/cuenta/recompensas', name: 'Mis Recompensas' },
  // { link: '/#tienda', name: 'Busca tu Tienda' },
  { link: '/sucursales', name: 'Sucursales' },
  { link: '/info/servicios', name: 'Servicios' },
  { link: '/info/entregas', name: 'Entregas' },
];

const itemsDesign = [
  // { link: "/facturacion", name: "Facturación Electronica" },
  { link: '/cuenta/recompensas', name: 'Mis Recompensas' },
  { link: '/info/servicios', name: 'Servicios' },
  { link: '/info/entregas', name: 'Entregas' },
];

const TopNav = () => {
  const location = JSON.parse(localStorage.getItem('location') || false);
  const [modalactive, setModalActive] = useState(false);
  const handleModal = () => {
    setModalActive((modalactive) => !modalactive);
  };

  const is_design = isDesign();
  const nav_items = is_design ? itemsDesign : items;

  useEffect(() => {
    if (!location) {
      setTimeout(() => {
        setModalActive(true);
      }, 800);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="top_nav xs_hidden">
        <div className="container">
          <div className="mini_nav_container">
            {' '}
            {nav_items.map((item, key) => (
              <a href={item.link} key={`topNav${key}`}>
                {item.name}
              </a>
            ))}
          </div>
          <div className="location_container">
            <button className="btn " onClick={handleModal}>
              <i className="fas fa-map-marker"></i> Sucursal{' '}
              {location ? location.city : '...'}
            </button>
          </div>
        </div>
      </div>
      <LocationsModal active={modalactive} toggle={handleModal} />
    </React.Fragment>
  );
};

export default TopNav;
