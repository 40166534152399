import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";

import { legal_address } from "../../../middleware/legal";
import states from "../../../data/states.json";
import { UPDATECLIENT, GETCLIENT } from "./queries";

const LegalInfo = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [fiscal_address, setFiscalAddress] = useState({ ...legal_address });
  const [invalid_data, setInvalidData] = useState({});
  const { data: user_db } = useQuery(GETCLIENT, {
    variables: { email: user.email },
    fetchPolicy: "no-cache",
  });
  const [setFiscal, { loading: saving_address, called: savedAddress }] =
    useMutation(UPDATECLIENT);
  useEffect(() => {
    if (user_db && user_db.xvcm) {
      const address_db = { ...fiscal_address };
      Object.keys(user_db.xvcm).map((key) => {
        const item = user_db.xvcm[key];
        if (!address_db[key]) {
          return;
        }
        if (address_db[key].default !== item) {
          address_db[key].value = item;
        }
      });
      setFiscalAddress({ ...address_db });
    }
  }, [user_db]);

  const handleType = (ev) => {
    const input = ev.target;
    const value = input.value;
    const type = input.dataset.type;
    const current_form = { ...fiscal_address };
    current_form[type].value = value;
    setFiscalAddress({ ...current_form });
  };

  const validateForm = () => {
    let valid = true;
    Object.keys(fiscal_address).map((key) => {
      const item = fiscal_address[key];
      if (!item.validate) return;
      if (item.value === "") {
        valid = item;
      }
    });
    if (valid === true) {
      saveData();
      setInvalidData({});
    } else {
      setInvalidData(valid);
    }
  };

  const saveData = async () => {
    const variables = {
      rfc: fiscal_address.regrfc.value.toUpperCase(),
      email: user.email,
      name: fiscal_address.nombre.value.toUpperCase(),
      state: fiscal_address.destdo.value.toUpperCase(),
      city: fiscal_address.dcidad.value.toUpperCase(),
      street: fiscal_address.dcalle.value.toUpperCase(),
      numext: fiscal_address.numext.value,
      numint: fiscal_address.numint.value,
      zip: fiscal_address.dcpost.value,
      colonia: fiscal_address.dcolon.value.toUpperCase(),
      municipio: fiscal_address.ddeleg.value.toUpperCase(),
    };

    await setFiscal({ variables });
  };

  return (
    <div className="legal_info">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          <p>Datos de facturación</p>
          <div className="form-row">
            {Object.keys(fiscal_address).map((key) => {
              const item = fiscal_address[key];
              if (key === "destdo") {
                return (
                  <div className={`form-group ${item.col}`}>
                    <label>Estado</label>
                    <select
                      className={`form-control ${
                        invalid_data.name === item.name ? "bg-warning" : ""
                      }`}
                      data-type={key}
                      onChange={handleType}
                      value={item.value}
                    >
                      {states.map((state) => (
                        <option
                          key={`state_${state.id}`}
                          id={state.id}
                          value={state.id}
                        >
                          {" "}
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              }
              return (
                <div className={`form-group ${item.col}`} key={`input${key}`}>
                  <label htmlFor={`input_${key}`}>{item.name}</label>
                  <input
                    id={`input_${key}`}
                    data-type={key}
                    type="text"
                    className={`form-control ${
                      invalid_data.name === item.name ? "bg-warning" : ""
                    }`}
                    value={item.value}
                    onChange={handleType}
                  />
                </div>
              );
            })}
          </div>
          <div className="row justify-content-end">
            {invalid_data.name && (
              <div className="col-4 text-right">
                <p className="text-danger">
                  Favor de agregar {invalid_data.name}
                </p>
              </div>
            )}
            {savedAddress && !saving_address ? (
              <div className="col-12 ">
                <div className="alert alert-success">
                  <p className="text-success">
                    <i className="far fa-check-circle"></i> Datos Fiscales
                    Actualizados
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-md-4">
              <button
                className="btn btn-block btn-success"
                onClick={validateForm}
                disabled={saving_address}
              >
                {saving_address ? "Guardando..." : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalInfo;
