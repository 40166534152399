import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { db } from '../../../middleware/firebase';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import LoaderImg from '../../../assets/cargando.png';

const Hero = ({ filter = 'img' }) => {
  const page =
    process.env.REACT_APP_IS_PROD === 'false'
      ? 'mathasa.mx'
      : window.location.host;
  const [activeselect, setActiveSelect] = useState('azulejos-ceramica');
  const [activelink, setActiveLink] = useState(false);
  const [banners, setBanners] = useState(false);
  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const bannersRef = db.collection('banners');
    const location = JSON.parse(
      localStorage.getItem('location') || '{"city":"Nacional","id":"0"}'
    );
    const set_banners = [];
    const start = new Date();
    const snapshot = await bannersRef
      .where('valid', '>=', start)
      .where('websites', 'array-contains', page)
      .get();
    if (snapshot.empty) {
      setBanners(false);
      console.log('No matching documents.');
      return;
    }

    snapshot.forEach((doc) => {
      const banner_data = doc.data();
      banner_data.id = doc.id;
      let add_it = true;
      if (
        typeof banner_data.from !== 'undefined' &&
        banner_data.from.toDate() > start
      ) {
        return;
      }
      if (banner_data.zones) {
        const finded = banner_data.zones.findIndex(
          (zone) => zone === location.id
        );
        if (finded < 0) {
          add_it = false;
        }
      }
      if (filter && !banner_data[filter]) {
        add_it = false;
      }
      if (add_it) {
        set_banners.push(banner_data);
      }
    });
    setBanners(set_banners.sort((a, b) => (a.order > b.order ? 1 : -1)));
  };

  const changeHandler = (ev) => {
    const value = ev.currentTarget.value;
    const level = ev.currentTarget.dataset.level;
    console.log('hero.js:85 | value', value);
    if (level === '1') {
      setActiveLink(false);
      setActiveSelect(value);
    } else if (value === 'false') {
      setActiveLink(false);
    } else {
      setActiveLink(value);
    }
  };
  if (banners === false) {
    return (
      <Carousel showThumbs={false}>
        <HeroItem
          item={{ img: LoaderImg }}
          changeHandler={changeHandler}
          activeselect={activeselect}
          activelink={activelink}
        />
      </Carousel>
    );
  }

  const arrowStyles = {
    border: 0,
    position: 'absolute',
    zIndex: 2,
    top: 0,
    width: 40,
    height: 'calc(100%)',
  };

  const indicatorStyles = {
    background: '#000000',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
  };

  return (
    <Carousel
      infiniteLoop={true}
      autoPlay={true}
      showStatus={false}
      showThumbs={false}
      showArrows={true}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            className="carouselArrow"
            type="button"
            onClick={onClickHandler}
            style={{ ...arrowStyles, left: 0 }}
          >
            <i
              className="fas fa-caret-left"
              style={{
                color: '#000000',
                fontSize: '32px',
              }}
            ></i>
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            className="carouselArrow"
            type="button"
            onClick={onClickHandler}
            style={{ ...arrowStyles, right: 0 }}
          >
            <i
              className="fas fa-caret-right"
              style={{
                color: '#000000',
                fontSize: '32px',
              }}
            ></i>
          </button>
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (banners.length > 1 && isSelected) {
          return (
            <li
              className="carouselIndicator"
              style={{
                ...indicatorStyles,
                background: '#000',
                width: 12,
                height: 12,
              }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        if (banners.length > 1) {
          return (
            <li
              className="carouselIndicator"
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }
      }}
    >
      {banners.map((item, key) => (
        <HeroItem
          key={`hero_${key}`}
          item={item}
          changeHandler={changeHandler}
          activeselect={activeselect}
          activelink={activelink}
          filter={filter}
        />
      ))}
    </Carousel>
  );
};
const HeroItem = ({
  item,
  changeHandler,
  activeselect,
  activelink,
  filter,
}) => (
  <div className="hero_container">
    <img src={item[filter]} alt={item.link} />

    {item.link && <Link className="full_link" to={item.link} />}
    {item.title && (
      <Container
        item={item}
        changeHandler={changeHandler}
        activeselect={activeselect}
        activelink={activelink}
      />
    )}
  </div>
);
const Container = ({ item, changeHandler, activeselect, activelink }) => (
  <div className="container">
    <div className="finder_select">
      <h1>{item.title}</h1>
      {item.options && (
        <Options
          optionsData={item.options}
          changeHandler={changeHandler}
          activeselect={activeselect}
        />
      )}
      {item.subtitle && <p>{item.subtitle}</p>}
      {item.button && (
        <a
          href={activelink}
          disabled={!activelink}
          className={`btn text-capitalize btn-block btn-light ${
            !activelink && 'opacity_0'
          }`}
        >
          {item.button.text}
        </a>
      )}
    </div>
  </div>
);

const Options = ({ optionsData, changeHandler, activeselect }) => {
  switch (optionsData.type) {
    case 'select':
      return rederSelect(optionsData, changeHandler, activeselect);

    default:
      return null;
  }
};

const rederSelect = (data, changeHandler, activeselect) => {
  const active_items = data.items.find((item) => item.id === activeselect);
  return (
    <React.Fragment>
      <Select level="1" changeHandler={changeHandler} data={data.items} />
      {active_items && (
        <Select
          level="2"
          changeHandler={changeHandler}
          active_items
          data={active_items.items}
        />
      )}
    </React.Fragment>
  );
};

const Select = ({ data, changeHandler, level }) => (
  <select className="form-control" onChange={changeHandler} data-level={level}>
    {level == 2 && <option value="false">Selecciona </option>}
    {data.map((item) => (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    ))}
  </select>
);

export default Hero;
