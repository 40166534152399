import React from 'react';
import { AltoValorBlock } from '../../common/products/productBlock';
const Products = ({ category_name, productsList, addProductCart }) => (
  <div className="">
    <div className="product_block_container">
      {Object.keys(productsList).map((key) => {
        const product = productsList[key];
        return (
          <AltoValorBlock
            key={product.id}
            product={product}
            addProductCart={addProductCart}
            from="list"
          />
        );
      })}
    </div>
  </div>
);

export default Products;
