import { formatNumber } from './common-functions';
const special_iva = ['3'];

export function getIva() {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  if (location.id === '0' || location.id === 0) {
    location.id = 1;
  }
  let iva = 0.16;
  if (special_iva.find((i) => i === location.id)) {
    iva = 0.08;
  }
  return iva;
}

export function priceIva(prices, default_price) {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  let price = default_price;
  if (location.id === '0' || location.id === 0) {
    location.id = '1';
  }
  const zone = prices.find((item) => {
    // eslint-disable-next-line
    return item.xiaz_id === location.id;
  });
  if (zone) {
    price = parseFloat(zone.price);
  }
  ///Ids of the locations with special IVA
  let iva = 0.16;
  if (special_iva.find((i) => i === location.id)) {
    iva = 0.08;
  }
  price = price * iva + price;
  price = Math.round((price + Number.EPSILON) * 100) / 100;
  return parseFloat(price.toFixed(2));
}
export function getRegularPrice(prices, prev_price) {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  if (location.id === '0' || location.id === 0) {
    location.id = '1';
  }
  let price = false;
  const zone = prices.find((item) => {
    // eslint-disable-next-line
    return item.xiaz_id === location.id;
  });
  if (!zone || !zone.ofrant) {
    return price;
  }
  price = parseFloat(zone.ofrant);
  let iva = 0.16;
  if (special_iva.find((i) => i === location.id)) {
    iva = 0.08;
  }
  price = price * iva + price;
  price = Math.round((price + Number.EPSILON) * 100) / 100;
  if (prev_price >= price) {
    return false;
  }
  return formatNumber(parseFloat(price.toFixed(2)));
}
