import React, { useEffect, useState } from "react";
import { db } from "../../../middleware/firebase";
import StatesImg from "../../../assets/estados.png";
import PinImg from "../../../assets/pin.png";
import { BranchItem, ZoneItem } from "../../../models/branch_model";
import Loader from "../../common/loader";

const Sucursales: React.FC = () => {
  const [branches, setBranches] = useState<ZoneItem[]>([]);
  const [zone, setZone] = useState<ZoneItem | false>(false);
  const [branch, setBranch] = useState<BranchItem | false>(false);
  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = () => {
    db.collection("warehouse")
      .get()
      .then((querySnapshot) => {
        const branches_obj: ZoneItem[] = [];
        querySnapshot.forEach((doc) => {
          branches_obj.push(doc.data() as ZoneItem);
        });
        setBranches(branches_obj);
      });
  };

  const handleBranchSelection = (ev: { currentTarget: HTMLElement }) => {
    const zone_id = ev.currentTarget.dataset.zone_id;
    const branch_id = ev.currentTarget.dataset.branch_id;
    const zone_key = branches.findIndex((zone) => zone.id === zone_id);
    const branch_data = branches[zone_key].xiams.find(
      (b) => b.id === branch_id
    );
    if (branch_data) {
      setBranch(branch_data);
    } else {
      setBranch(branches[zone_key].xiams[0]);
    }
  };

  const handleZoneSelection = (ev: { currentTarget: HTMLElement }) => {
    const zone_id = ev.currentTarget.dataset.zone_id;
    const selected_zone = branches.find((zone) => zone.id === zone_id);
    if (selected_zone) {
      setZone(selected_zone);
    } else {
      setZone(branches[0]);
    }
  };

  return (
    <main className="container first-container  last-container sucurales">
      <h1>Sucursales</h1>
      <p>
        Encuentra la dirección y número de teléfono de la sucursal de tu
        preferencia. Haciendo clic en el municipio en el mapa y ve los detalles
        de la sucursal en el lateral derecho
      </p>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1WZ5rfLXJlW3xyaLuLcrZ6lV23Lr2ow0q"
        style={{ border: "none" }}
        width="100%"
        height="480"
      ></iframe>
    </main>
  );
};

type AccordionZoneItemProps = {
  zone: ZoneItem;
  handleSelection: any;
  handleBranchSelection: any;
  selected_zone: string;
  selected_branch: BranchItem | false;
};

const AccordionZoneItem: React.FC<AccordionZoneItemProps> = ({
  handleSelection,
  handleBranchSelection,
  zone,
  selected_zone,
  selected_branch,
}) => (
  <div className="card hover ">
    <div
      className="card-header"
      onClick={handleSelection}
      data-zone_id={zone.id}
    >
      <p className="mb-0 text-capitalize">
        {zone.idzalm.toLocaleLowerCase()}{" "}
        <span className="text-muted small">
          | {`${zone.descrp?.toLocaleLowerCase()}`}
        </span>
      </p>
    </div>

    <div className={`collapse ${selected_zone === zone.id ? "show" : ""}`}>
      <div className="card-body bg-light px-1 py-2">
        {zone.xiams.map((branch_item) => (
          <AccordionItem
            key={`AccordionItem_${branch_item.id}`}
            branch={branch_item}
            zone_id={zone.id}
            handleSelection={handleBranchSelection}
            selected_branch={selected_branch}
          />
        ))}
      </div>
    </div>
  </div>
);

type AccordionItemProps = {
  branch: BranchItem;
  handleSelection: any;
  zone_id: string;
  selected_branch: BranchItem | false;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  handleSelection,
  branch,
  zone_id,
  selected_branch,
}) => (
  <div
    className="card hover mb-2"
    onClick={handleSelection}
    data-zone_id={zone_id}
    data-branch_id={branch.id}
  >
    <div className="card-header bg-white">
      <p className="mb-0 text-capitalize">
        <i className="fas fa-map-marker-alt mr-2 text-secondary"></i>
        {`${branch.descrp?.toLocaleLowerCase()}`}{" "}
      </p>
    </div>

    <div
      className={`collapse ${
        selected_branch && selected_branch.idalma === branch.idalma
          ? "show"
          : ""
      }`}
    >
      <div className="card-body bg-light">
        <DescRow
          icon="fas fa-map-marker-alt"
          text={branch.address || "-"}
          classes="text-capitalize"
        />
        {branch.xiads && (
          <DescRow
            icon="fas fa-phone"
            text={branch.xiads[0].xiam.telefn || "-"}
          />
        )}
      </div>
    </div>
  </div>
);

type DescRowProps = {
  icon: string;
  text: string;
  classes?: string;
};
const DescRow: React.FC<DescRowProps> = ({ icon, text, classes }) => (
  <div className={`mt-3 desc_row row align-items-center ${classes}`}>
    <div className="col-1">
      <i className={`${icon} fa-fw text-info`}></i>
    </div>
    <div className="col-10">
      <p className="p-0 m-0">{text}</p>
    </div>
  </div>
);

function urlAddress(address: string | undefined): string {
  if (!address) return "error";
  return address
    .replace(/,/g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .split(" ")
    .join("+");
}

export default Sucursales;
