import React, { Component } from "react";
import SearchNav from "../../nav/SearchNav";
import MiniTopNav from "../../nav/miniTopNav";
class Header extends Component {
  render() {
    return (
      <header>
        <MiniTopNav />
        <SearchNav />
      </header>
    );
  }
}

export default Header;
