import { gql } from "apollo-boost";

export const GETORDER = gql`
  query($order_id: String!) {
    xvoms(idvnta: $order_id) {
      id
      statvt
      fhvenc
      mdescu
      mimpto
      statem
      mrecog
      msubto
    }
  }
`;

export const GETCLIENT = gql`
  query($email: String!) {
    xvcm(emailc: $email) {
      dcalle
      dcidad
      dcolon
      dcpost
      ddeleg
      destdo
      dipais
      nombre
      numext
      regrfc
      numint
      brtday
      sldrec
    }
  }
`;

export const UPDATECLIENT = gql`
  mutation(
    $email: String!
    $name: String!
    $state: String
    $city: String
    $street: String
    $numext: String
    $numint: String
    $zip: String
    $colonia: String
    $municipio: String
    $rfc: String
  ) {
    createXvcm(
      xvcmInput: {
        dcalle: $street
        dcidad: $city
        dcolon: $colonia
        dcpost: $zip
        ddeleg: $municipio
        destdo: $state
        dipais: "MX"
        nombre: $name
        numext: $numext
        regrfc: $rfc
        numint: $numint
        emailc: $email
      }
    ) {
      idclte
      xvcds {
        id
      }
    }
  }
`;

export const SAVEADDRESS = gql`
  mutation(
    $email: String!
    $name: String!
    $state: String
    $city: String
    $street: String
    $numext: String
    $numint: String
    $zip: String
    $colonia: String
    $municipio: String
    $rfc: String
    $addressID: String
  ) {
    createXvcm(
      xvcmInput: {
        dcalle: $street
        dcidad: $city
        dcolon: $colonia
        dcpost: $zip
        ddeleg: $municipio
        destdo: $state
        dipais: "MX"
        nombre: $name
        numext: $numext
        regrfc: $rfc
        numint: $numint
        emailc: $email
      }
    ) {
      idclte
      xvcds {
        id
      }
    }
  }
`;
