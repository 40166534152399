import React from "react";
import { ProductBlock } from "../../common/products/productBlock";
import useProducts from "../../../hooks/useProducts";

const RelatedProducts = ({
  types = null,
  type = "grpsbi",
  neddedProducts_ids = [],
  relatedTypes = [],
}) => {
  const needed = getNeededInfo(neddedProducts_ids);
  console.log("related.js:12 | needed", needed);
  const ids = !needed ? null : needed.map((item) => item.product_id);

  const filters = {
    limit: 10,
    grpsbi: types,
    ids,
    types: [relatedTypes],
  };
  const { result: products } = useProducts({ ...filters });
  if (!products || products.length === 0) {
    return null;
  }
  const title =
    type == "grpsbi"
      ? "Productos Relacionados"
      : "Productos Necesarios o Recomendados para la instalación.";
  return (
    <div className=" container my-3">
      <h3 className="underlined my-4">{title}</h3>
      <Products products={products} />
    </div>
  );
};

const Products = ({ products }) => {
  if (!products) {
    return null;
  }
  const related_product = Object.keys(products)
    .filter((item, key) => (key <= 5 ? true : false))
    .map((item) => products[item]);
  return (
    <div className="product_block_container">
      {related_product.map((product, id) => (
        <ProductBlock
          key={id}
          product={product}
          unit={product.xium ? product.xium.descrp : "pz"}
        />
      ))}
    </div>
  );
};

function getNeededInfo(neddedProducts_ids) {
  if (!neddedProducts_ids) return [];
  return neddedProducts_ids.map((item, key) => {
    return { product_id: item.ptrl.id, cant: item.cantid };
  });
}

export default RelatedProducts;
