import { useAsync } from "react-async-hook";
import { db } from "../middleware/firebase";

export type ProductTag = {
  productTagId: string;
  primaryType: string;
  secondaryType: string;
  tertiaryType: string;
  count: Number;
  products: string[];
};

export async function getProductTags() {
  const productTags: ProductTag[] = (
    await db.collection("product-tags").get()
  ).docs.map((doc) => doc.data()) as any;
  const tagsDictionary: {
    [PrimaryTag: string]: { [SecondaryTag: string]: string[] };
  } = {};

  productTags.forEach(({ primaryType, secondaryType, tertiaryType }) => {
    if (!tagsDictionary[primaryType]) tagsDictionary[primaryType] = {};
    const tertiaryTags = tagsDictionary[primaryType][secondaryType] || [];
    tagsDictionary[primaryType][secondaryType] = [
      ...tertiaryTags,
      tertiaryType,
    ];
  });
  return tagsDictionary;
}

export default function useProductTags() {
  return useAsync(getProductTags, []);
}
