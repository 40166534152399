import React from "react";
import { ZoneItem } from "../../../models/branch_model";
import { DeliveryTypes } from "../../../models/checkout_model";
import { UserDeliveryData } from "../../../models/user_model";
import { RichButton } from "../../common/buttons";
import UserAddress from "./address";
import BranchSelection from "./branchSelection";

type DeliveryProps = {
  delivery_type: DeliveryTypes;
  setDelivery_type: any;
  error_message: any;
  handleDelivery: any;
  deliveryData: UserDeliveryData;
  addresses: UserDeliveryData[] | "loaded" | null;
  loading: boolean;
  selectAddress: any;
  branches: ZoneItem[];
  selectBranch: any;
  active_branch: any;
  active_city: string | false;
  handleCitySelect: any;
  clickFun: any;
};

const Delivery: React.FC<DeliveryProps> = ({
  delivery_type,
  setDelivery_type,
  handleDelivery,
  deliveryData,
  addresses,
  loading,
  selectAddress,
  branches,
  selectBranch,
  active_branch,
  active_city,
  handleCitySelect,
  clickFun,
  error_message,
}) => (
  <React.Fragment>
    {" "}
    <p>Forma de Envío:</p>
    <div className="btn_rich_container mob_full">
      <RichButton
        title="Yo lo Recojo"
        small={true}
        icon="fas fa-map-marker-alt"
        help="En tu sucursal más cercana"
        active={delivery_type === "pickup"}
        value="pickup"
        clickFun={setDelivery_type}
      />
      <RichButton
        title="Envío a Domicilio"
        small={true}
        icon="fas fa-shipping-fast"
        help="Costo de Envío en el siguiente paso"
        active={delivery_type === "shipping"}
        value="shipping"
        clickFun={setDelivery_type}
      />
    </div>
    <div className="row mt-3">
      <div className="col-md-12">
        {delivery_type === "shipping" ? (
          <UserAddress
            clickFun={clickFun}
            handleDelivery={handleDelivery}
            deliveryData={deliveryData}
            addresses={addresses != "loaded" ? addresses : null}
            loading={loading}
            selectAddress={selectAddress}
            error_message={error_message}
          />
        ) : (
          <BranchSelection
            branches={branches}
            clickFun={clickFun}
            selectBranch={selectBranch}
            active_branch={active_branch}
            active_city={active_city}
            handleCitySelect={handleCitySelect}
          />
        )}
      </div>
    </div>
  </React.Fragment>
);

export default Delivery;
