import React from "react";
import { Link } from "react-router-dom";
type EmptyJumbotronProps = {
  icon: string;
  heading: string;
  sub_heading?: string;
  text?: string;
  cta?: {
    type: "link" | "button";
    txt: string;
    to: string;
    classes: string;
  };
};
const EmptyJumbotron: React.FC<EmptyJumbotronProps> = ({
  icon,
  heading,
  sub_heading,
  text,
  cta,
}) => (
  <div className="empty_jumbo">
    <i className={`${icon}`}></i>
    <h3>{heading}</h3>
    <h4>{sub_heading}</h4>
    <p>{text}</p>
    {cta ? (
      cta.type === "link" ? (
        <Link to={cta.to} className={`btn ${cta.classes}`}>
          {" "}
          {cta.txt}{" "}
        </Link>
      ) : (
        <button className={`btn ${cta.classes}`}>{cta.txt}</button>
      )
    ) : null}
  </div>
);

export default EmptyJumbotron;
