import React from "react";

import Loader from "../../common/loader";
import { CardIcon } from "../../common/cards.js";
import { AddressComponent } from "./addressComponent";
import { UserDeliveryData } from "../../../models/user_model";

type UserAddressProps = {
  clickFun: any;
  handleDelivery: any;
  deliveryData: UserDeliveryData;
  addresses: UserDeliveryData[] | null;
  loading: boolean;
  selectAddress: any;
  error_message: any;
};

const UserAddress: React.FC<UserAddressProps> = ({
  clickFun,
  handleDelivery,
  deliveryData,
  addresses,
  loading,
  selectAddress,
  error_message,
}) => {
  const PrevAddress = () => (
    <div className="prev_addresses">
      <h5>Selecciona una Dirección Anterior</h5>
      {addresses &&
        addresses.map((address, key) => {
          console.log("address.tsx:32 | address", address);
          return (
            <CardIcon
              key={`CardIcon_${address.id}_${key}`}
              icon="fas fa-map-marker-alt"
              title={`${address.name} ${address.lastname}`}
              desc={`${address.address} ${address.city}, ${address.state}`}
              cta="Seleccionar"
              clickFun={selectAddress}
              dataAttr={address.id}
              selected={deliveryData.prev === address.id}
              size="sm"
            />
          );
        })}
      <h5>O Agrega una Nueva Dirección</h5>
    </div>
  );

  if (loading) {
    return (
      <div className="">
        <Loader />
      </div>
    );
  }

  return (
    <div className="user_address ">
      {addresses && <PrevAddress />}
      {deliveryData.prev && (
        <button
          className="btn btn-link btn-block my-4"
          onClick={selectAddress}
          data-attr="false"
        >
          Agregar Nueva Dirección
        </button>
      )}

      <AddressComponent
        deliveryData={deliveryData}
        handleDelivery={handleDelivery}
      />
      <div className="text-right my-5 d-md-none">
        {error_message && (
          <div className="alert alert-danger" id="alert-shake">
            <i className="fas fa-exclamation-circle mr-2"></i>
            {error_message}
          </div>
        )}
        <button
          onClick={clickFun}
          type="button"
          data-next="payment"
          className="btn btn-cart btn-block text-capitalize py-4 px-5 fixed"
        >
          Continuar
        </button>
      </div>
    </div>
  );
};

export default UserAddress;
