import React from "react";
import { ListBlock } from "../../common/products/productBlock";
const Products = ({ category_name, productsList, addProductCart }) => {
  const products_by_cat = {};
  productsList.map((item) => {
    const cat = item.grpsbiStr_lvl2.split(" ").join();
    if (!products_by_cat[cat]) {
      products_by_cat[cat] = {
        key: cat,
        name: item.grpsbiStr_lvl2.split("_").join(" / "),
        products: [],
      };
    }
    products_by_cat[cat].products.push(item);
    return true;
  });

  return Object.values(products_by_cat).map((item) => (
    <div className="" key={item.key}>
      <h5>{item.name}</h5>

      <div className="product_block_container">
        <RenderProducts
          products={item.products}
          addProductCart={addProductCart}
        />
      </div>
      <hr />
    </div>
  ));
};

const RenderProducts = ({ products, addProductCart }) => {
  return products.map((product, key) => {
    return (
      <ListBlock
        key={product.id}
        product={product}
        addProductCart={addProductCart}
        from="list"
      />
    );
  });
};

export default Products;
