import React, { useEffect } from 'react';
import { AditionalProducts, getNeededInfo } from '../../common/modals/modals';
import useProducts from '../../../hooks/useProducts';

const NeddedProducts = ({
  neddedProducts_ids,
  product_amount,
  relatedTypes,
  product_title,
}) => {
  const needed = getNeededInfo(neddedProducts_ids);
  const ids = !needed ? null : needed.map((item) => item.product_id);
  const { result: neddedProducts } = useProducts({
    ids: ids,
    types: [relatedTypes],
    limit: 10,
  });
  if (!neddedProducts || neddedProducts.length === 0) {
    return null;
  }

  return (
    <div className=" container my-3">
      <AditionalProducts
        type={needed.length ? 'needed' : 'related'}
        products={neddedProducts}
        updateCart={() => {}}
        neddedInfo={needed}
        product_amount={product_amount}
        product_title={product_title}
      />
    </div>
  );
};

export default NeddedProducts;
