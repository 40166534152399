import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import RelatedProducts from './related';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { AddProductModal } from '../../common/modals/modals';
import ProductSection from './productComponents';
import FeaturesSection from './featuresSection';
import useProduct from '../../../hooks/useProduct';
import Loader from '../../common/loader';
import { addProductCart } from '../../../middleware/cartFunctions';
import Page404 from '../page404';

const Product = () => {
  const { product_id } = useParams();
  const [modal, setModal] = useState(false);
  const [active_image, setActive_image] = useState(0);
  const [current_amount, setCurrent_amount] = useState(1);
  const amount = useRef(1);
  const [inputValue, setInputValue] = useState('');

  const { loading, error, result: product_data } = useProduct(product_id);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.PRODUCTS_DETAIL);
    }
    return function cleanup() {
      if (typeof window.vCustomer != 'undefined') {
        window.vCustomer.finishEvent(
          window.vCustomer.EVENT_TAGS.PRODUCTS_DETAIL
        );
      }
    };
  }, []);
  useEffect(() => {
    const product_amount = parseInt(amount.current.value);
    console.log('index.js:40 | product_amount', product_amount);
    setCurrent_amount(product_amount);
  }, [amount]);

  const promtAddProductCart = async () => {
    let product_amount = parseInt(amount.current.value);
    product_amount = isNaN(product_amount) ? 1 : product_amount;
    setCurrent_amount(product_amount);
    await addProductCart({ product_amount, product_id, product_data });
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
  };

  const handleImage = (ev) => {
    setActive_image(ev);
  };

  const BreadcrumbSection = ({ types }) => (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to={`/lista/${encodeURIComponent(types[0])}/`}>{types[0]}</Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Link
          to={`/lista/${encodeURIComponent(types[0])}/${encodeURIComponent(
            types[1]
          )}`}
        >
          {types[1]}
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem active>
        <Link
          to={`/lista/${encodeURIComponent(types[0])}/${encodeURIComponent(
            types[1]
          )}/${encodeURIComponent(types[2])}`}
        >
          {types[2]}
        </Link>
      </BreadcrumbItem>
    </Breadcrumb>
  );
  if (loading)
    return (
      <div className="container first-container">
        <Loader full />
      </div>
    ); ///pending loader

  if (error) {
    return <div className="error">"Error"</div>;
  }
  if (!product_data.price || product_data.price === '0.00') {
    return <Page404 />;
  }
  return (
    <div className="container first-container">
      <Helmet>
        <title>{product_data.descp1}</title>
        <meta name="description" content={product_data.description} />
        <script src="//cdn.doofinder.com/recommendations/js/doofinderRecommendation.min.js"></script>
      </Helmet>
      <BreadcrumbSection types={product_data.grpsbi} />
      <ProductSection
        promptAdd={promtAddProductCart}
        product={product_data}
        amount={amount}
        active_image={active_image}
        handleImage={handleImage}
        onUpdate={handleInputChange}
      />
      <FeaturesSection
        product_id={product_data.id}
        aditional={product_data.additionalData || false}
        ptrl={product_data.ptrl}
        product_amount={inputValue}
        product_title={product_data.descp1}
      />
      <div className=" container my-3">
        <h3 className="underlined my-4">Productos Relacionados</h3>
        <df-recommendations
          hashid="bad7e4a1c656512524301a9c92e1eac1"
          total-products="10"
          region="us1"
        ></df-recommendations>
      </div>
      <AddProductModal
        active={modal}
        product_title={product_data.descp1}
        type={product_data.xium ? product_data.xium.descrp : 'pz'}
        toggle={hideModal}
        neddedProducts_ids={product_data.ptrl}
        relatedTypes={product_data.grpsbi[2]}
        product_amount={current_amount}
      />
    </div>
  );
};
export default Product;
