import React, { useEffect, useState } from 'react';
import { db } from '../../../middleware/firebase';
import { getRegularPrice, priceIva } from '../../../middleware/iva';
import { formatNumber } from '../../../middleware/common-functions';
import { getProductUrl } from '../../../middleware/common-functions';

const Promotions = ({ is_design = false }) => {
  const [promotions, setPromotions] = useState([]);
  useEffect(() => {
    getPromotions();
  }, []);

  const getPromotions = async () => {
    try {
      const snapshot = await db.collection('sale_products').get();
      const promotion_data = snapshot.docs
        .map((doc) => {
          let promoData = doc.data();
          const sale_for_design = !!(promoData.m_page == 'designmathasa.mx'); // check for pagefilter == designcenter and convert to bool
          const productUrl = getProductUrl(promoData);
          promoData.productUrl = productUrl;
          if (is_design == sale_for_design) {
            return promoData;
          }
          return null;
        })
        .filter((promoData) => promoData !== null); // filter out if null
      await getPrices(promotion_data);

      setPromotions(
        promotion_data.sort((a, b) => (a.m_order > b.m_order ? 1 : -1))
      );
    } catch (error) {
      console.error('Error fetching promotions:', error);
    }
  };

  const getPrices = async (products) => {
    for (const product of products) {
      product.price_num = await priceIva(product.zonePrice, product.price);
      product.price = formatNumber(product.price_num);
      product.price_regular = await getRegularPrice(
        product.zonePrice,
        product.price_num
      );
    }
  };

  return (
    <div className="promotions">
      {promotions.map((item, key) => (
        <div key={`promotion_item_${key}`}>
          <a href={item.productUrl}>
            <img
              className="border"
              src={`${item.m_img ? item.m_img : item.images[0]}?v=2)`}
              alt={`Product ${key}`}
            />
          </a>
          <a href={item.productUrl}>
            <div className="font-weight-bold text-uppercase text-dark">
              {item.descp1}
            </div>
          </a>
          {item.price_regular ? (
            <a href={item.productUrl}>
              <div className="regular-price small text-uppercase text-dark">
                ${item.price_regular}
              </div>
            </a>
          ) : null}
          <a href={item.productUrl}>
            <div className="text-uppercase text-dark">${item.price}</div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Promotions;
