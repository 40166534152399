import React from "react";

export const CardIcon = ({
  icon,
  title,
  desc,
  cta,
  dataAttr,
  clickFun,
  size,
  selected,
}) => (
  <div
    className={`card card_icon hover w-100 mb-4 ${size} ${
      selected && "selected"
    }`}
    onClick={clickFun}
    data-attr={dataAttr}
  >
    <div className="card-body">
      <div className="row jus">
        <div className="col-md-8 d-flex align-items-center">
          <div className="icon_rounded white mr-3">
            <i className={icon}></i>
          </div>
          <div>
            {" "}
            <p>{title}</p>
            <small>{desc}</small>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center">
          <button className="btn btn-link text-capitalize text-blue btn-sm btn-block">
            {cta}
          </button>
        </div>
      </div>
    </div>
  </div>
);
