import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";

const benefits = [
  "Verifica el estado de tus compras",
  "Recibe promociones muy atractivas",
  "Compra con menos clicks",
];

const ForgotPage = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const email = useRef(false);
  const forgotHandler = async () => {
    if (!email.current.value) return false;
    setLoading(true);
    try {
      authContext.forgotFun(email.current.value).then(() => {
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      console.log("index.js:21 | error", error);
      setLoading(false);
      switch (error.error) {
        case "auth/user-not-found":
          setError("No tenemos registro de usuarios con ese correo");
          break;
        default:
          setError("No tenemos registro de usuarios con ese correo");
          break;
      }
    }
  };

  return (
    <div className="container first-container last-container ">
      <div className="row justify-content-between my-5 ">
        {success ? (
          <div className="p-4 col-md-6">
            <h2 className="underlined my-4 py-2">Correo envíado</h2>
            <p>
              Se ha enviado un mensaje a tu correo para restaurar tu contraseña
            </p>
          </div>
        ) : (
          <div className="p-4 col-md-6">
            <h2 className="underlined my-4 py-2">Recuperar Contraseña</h2>
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control" ref={email} />
            </div>

            {error && <div className="alert alert-waring">{error}</div>}
            <button
              className="btn btn-dark btn-block mb-3"
              onClick={forgotHandler}
              disabled={loading}
            >
              Solicitar Contraseña
            </button>
          </div>
        )}
        <div className="p-4 col-md-5 border-left">
          <h2 className="underlined my-4 py-2 opacity_0">Login</h2>
          <h4>¿No tienes cuenta?</h4>
          <p>
            Crea tu cuenta gratis y disfruta de los beneficios de comprar online
            en Mathasa
          </p>
          <Link
            to="/signup"
            className="btn btn-outline-secondary btn-block text-center"
          >
            Crear cuenta
          </Link>
          <ul className="mt-4">
            {benefits.map((item, key) => (
              <li key={`benefits_${key}`}>
                <i className="fas fa-check text-success mr-2"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ForgotPage;
