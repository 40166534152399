import React from 'react';
import { ListBlock } from '../../common/products/productBlock';
const Products = ({ category_name, productsList, addProductCart }) => (
  <>
    <h1 className="underlined my-4 text-capitalize">
      {category_name && category_name.split('-').join(' ')}
    </h1>
    <div className="product_block_container">
      {productsList.map((product) => {
        return (
          <ListBlock
            key={product.id}
            product={product}
            addProductCart={addProductCart}
            from="list"
          />
        );
      })}
    </div>
  </>
);

export default Products;
