import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import moment from "../../../middleware/moment";

import { db } from "../../../middleware/firebase";
import Loader from "../../common/loader";
import EmptyJumbotron from "../../common/emptyJumbotron";

const Orders = ({ router }) => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getOrders();
  }, []);
  const getOrders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    db.collection("quotes")
      .doc(user.id)
      .collection("quotes")
      .get()
      .then(function (querySnapshot) {
        const orders_obj = {};
        let x = 1;
        querySnapshot.forEach(function (doc) {
          orders_obj[doc.id] = doc.data();
          x++;
        });
        if (x > 1) {
          const url = router.location.pathname.split("/");
          if (url.length >= 4) {
            const order_id = url[3];
            const selected_oder = orders_obj[order_id];
          }
          setOrders(orders_obj);
        } else {
          setOrders(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error getting documents: ", error);
      });
  };
  const selectOrder = (ev) => {
    const order_id = ev.currentTarget.dataset.order_id;
    const selected_oder = orders[order_id];
    router.history.push(`/cuenta/cotizacion/${order_id}`);
  };

  return (
    <div className="my_orders">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {loading ? (
            <Loader />
          ) : orders ? (
            <OrdersTable data={orders} clickFun={selectOrder} />
          ) : (
            <EmptyJumbotron
              icon="fas fa-clipboard-list"
              heading="Sin Cotizaciones Guardadas"
              text="Guarda tus cotizaciones desde el carrito de compra"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrdersTable = ({ data, clickFun }) => {
  if (isMobile) {
    return (
      <div className="list-group">
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <button
              onClick={clickFun}
              className="list-group-item list-group-item-action "
              key={key}
              data-order_id={key}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">${item.total.total}</h5>
                <small>
                  {moment(item.timestamp.toDate()).format("MMM DD ")}
                </small>
              </div>
              <p className="mb-1">{item.name}</p>
            </button>
          );
        })}
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col">Total</th>
          <th scope="col">Fecha</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <tr key={key} onClick={clickFun} data-order_id={key}>
              <th scope="row">{index + 1}</th>
              <td>{item.name}</td>
              <td>${item.total.total}</td>
              <td>{moment(item.timestamp.toDate()).format("MMM DD ")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Orders;
