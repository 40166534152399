import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../middleware/firebase";
import Loader from "../../common/loader";
import EmptyJumbotron from "../../common/emptyJumbotron";

const Address = ({ router }) => {
  const [addresses, setAddresses] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    db.collection("addresses")
      .doc(user.id)
      .collection("address")
      .get()
      .then(function (querySnapshot) {
        const orders_obj = {};
        let x = 1;
        querySnapshot.forEach(function (doc) {
          orders_obj[doc.id] = doc.data();
          x++;
        });
        if (x > 1) {
          setAddresses(orders_obj);
        } else {
          setAddresses(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error getting documents: ", error);
      });
  };
  const selectAddress = (ev) => {
    const address_id = ev.currentTarget.dataset.address;
    router.history.push(`/cuenta/direccion/${address_id}`);
  };

  return (
    <div className="my_address">
      <div className="card  material-shadows mb-3">
        <div className="card-body ">
          {loading ? (
            <Loader />
          ) : addresses ? (
            <AddressTable data={addresses} selectAddress={selectAddress} />
          ) : (
            <EmptyJumbotron
              icon="fas fa-map-marker-alt"
              heading="Sin direcciones guardadas"
              text="Tus direcciones son guardadas a la hora de hacer un pedido"
            />
          )}
          <Link className="btn btn-success  btn-block" to="/cuenta/direccion">
            Agregar Nueva
          </Link>
        </div>
      </div>
    </div>
  );
};

const AddressTable = ({ data, selectAddress }) => {
  return (
    <table className="table table-striped table-hover hover">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Dirección</th>
          <th scope="col">CP</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((key) => {
          const item = data[key];
          return (
            <tr key={key} onClick={selectAddress} data-address={key}>
              <td>{item.name}</td>
              <td>{item.address + ", " + item.city}</td>
              <td>{item.zip}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Address;
