import axios from "axios";
const url = "https://blog.mathasa.mx/wp-json/wp/v2/";

export function getPost(post_id) {
  return new Promise((res, rej) => {
    axios
      .get(`${url}posts/${post_id}`)
      .then(({ data }) => {
        console.log("wordpress_api.js:7 | data", data);
        res(data);
      })
      .catch((err) => {
        console.log("err", err);
        rej(err);
      });
  });
}
