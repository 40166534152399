import React from 'react';

const production = process.env.REACT_APP_PROD === 'true';
declare global {
  interface Window {
    gtag: any;
  }
}

export type EcommerceData = {
  name: string;
  id: string;
  revenue: string;
  subscription: string;
  sku: string;
  category: string;
};

export const AnalyticsContext = React.createContext({
  event: (data: { action: string; value: number }) => {},
  view: (location: string) => {},
  ecommerce: (data: EcommerceData) => {},
});

const AnalyticsContextProvider: React.FC = ({ children }) => {
  const event = (data: { action: string; value: number }) => {
    if (!production) {
      return false;
    }
    window.gtag('event', data.action, {
      value: data.value,
    });
  };

  const pageview = (location: string) => {
    if (!production || !location) return false;
    window.gtag('event', 'page_view', {
      page_path: location,
    });
  };

  const ecommerce = (data: EcommerceData) => {
    if (!production) return false;

    if (!data) return false;
    window.gtag('event', 'purchase', {
      transaction_id: data.id,
      affiliation: 'mathasa.mx',
      value: data.revenue,
      currency: 'MXN',
      shipping: 0,
      items: [
        {
          id: data.sku, // SKU/code,
          name: data.subscription, // Product name. Required,
          category: data.category, // Category or variation,
          quantity: 1,
          price: data.revenue,
        },
      ],
    });
    // ReactGA.plugin.execute("ecommerce", "addTransaction", {
  };

  return (
    <AnalyticsContext.Provider
      value={{
        event: event,
        view: pageview,
        ecommerce,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
