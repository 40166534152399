import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Helmet } from 'react-helmet';

import firebase, { db } from '../../../middleware/firebase';
import axios from '../../../middleware/axios';
import { concatProductsTitle } from '../../../middleware/products';
import CartItems from './cartItems';
import RelatedProducts from './relatedProducts';
import Totals from './totals';
import Loader from '../../common/loader';
import EmptyJumbotron from '../../common/emptyJumbotron';
import { SaveOrderModal } from '../../common/modals/modals';
import {
  formatNumber,
  updateCartCants,
} from '../../../middleware/common-functions';
import { getCart } from '../../../middleware/cartFunctions';
import { Totals as TotalsType } from '../../../models/checkout_model';
import { CartProductItem, PromotionItem } from '../../../models/cart_model';

const CartPage = () => {
  const [total, setTotal] = useState<TotalsType>({ total: 0, subtotal: 0 });
  const [products, setProducts] = useState<CartProductItem[]>([]);
  const [updateproduct, setUpdateProduct] = useState<CartProductItem | false>(
    false
  );
  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState<PromotionItem | false>(false);
  const [modalactive, setModalActive] = useState(false);
  const [error_message, setError_message] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const get_total = sumup();
    setTotal(get_total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, promotion]);

  ///update cart con qty manipulations
  useEffect(() => {
    let update_timer = setTimeout(() => {
      updateCart();
    }, 1000);
    return () => {
      clearTimeout(update_timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  //Check if coupon is still valid when total changes
  useEffect(() => {
    if (promotion) {
      setLoading(true);
      let update_timer = setTimeout(() => {
        setLoading(false);
        const fetchPromo = async () => {
          const get_promotion = await getPromoCode(promotion.code);
          return get_promotion;
        };
        const fetchData = async () => {
          const result = await fetchPromo();
          if (result.error) {
            await removePromoCode(promotion.code);
            setError_message(result.error);
          }
        };

        fetchData();
      }, 1100);
      return () => {
        clearTimeout(update_timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, promotion]);

  const getProducts = async () => {
    if (!user.id) {
      setLoading(false);
      return false;
    }

    const { cart_products, promotion } = await getCart();
    setProducts(cart_products);
    setPromotion(promotion);
    setLoading(false);
  };

  const updateCart = () => {
    if (!updateproduct) return false;
    const collection = db.collection('cart').doc(user.id);

    collection
      .collection('products')
      .doc(updateproduct.id)
      .update({ cant: updateproduct.cant })
      .then(() => {
        setUpdateProduct(false);
      });
  };

  const sumup = (): TotalsType => {
    if (products.length < 1) return { total: 0, subtotal: 0 };
    let discount = 0;
    let sum = products
      .map((item) => {
        if (item.regluar_price) {
          const discounted_price = item.regluar_price - item.price;
          const discounted = discounted_price * item.cant;
          discount += discounted;
        }
        return item.price * item.cant;
      })
      .reduce((a, b) => a + b, 0);
    if (promotion) {
      switch (promotion.type) {
        case 'total':
          sum = sum - promotion.value;
          discount = promotion.value;
          break;
        case 'percentage_all':
          const discount_promo = sum * promotion.value;
          sum = sum - discount_promo;
          discount = discount_promo;
          break;
        default:
          break;
      }
    }
    const response = {
      total: formatNumber(sum),
      subtotal: formatNumber(sum),
      discount: formatNumber(discount),
    };
    return response;
  };

  const handleDelete = (ev: { currentTarget: HTMLButtonElement }) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const current_cart = [...products];
    //get the index of the deleted product
    const index = current_cart.findIndex((product) => {
      return product.id === product_id;
    });
    ///send vesta event
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.REMOVE_FROM_CART);
      setTimeout(() => {
        window.vCustomer.finishEvent(
          window.vCustomer.EVENT_TAGS.REMOVE_FROM_CART
        );
      }, 500);
    }
    //remove from array
    current_cart.splice(index, 1);
    setProducts(current_cart);
    const collection = db.collection('cart').doc(user.id);
    collection.collection('products').doc(product_id).delete();
    localStorage.setItem('cart', JSON.stringify(current_cart));
    updateCartCants();
  };
  const CartContent = () => (
    <React.Fragment>
      <CartItems
        products={products}
        handleDelete={handleDelete}
        changeFun={handleQtyChange}
      />
      <div className="row border-top py-3 ">
        <div className="col-md-6 order-md-2">
          <Totals
            total={total}
            handleModal={user.isAnonymous ? goToLogin : handleModal}
            cuponApply={cuponApply}
            promotion={promotion}
            removePromoCode={removePromoCode}
            erros={error_message}
          />
        </div>
        <div className="col-md-6 border-right order-md-1 mt-3 mt-md-0">
          <RelatedProducts cart_products={products} updateCart={getProducts} />
        </div>
      </div>
    </React.Fragment>
  );

  const goToLogin = () => {
    window.location.href = '/login';
  };

  const handleModal = () => {
    setModalActive((modalactive) => !modalactive);
  };

  const saveOrder = () => {
    /// get name of quote
    const quote_name: HTMLInputElement | null = document.getElementById(
      'quote_name'
    ) as HTMLInputElement;
    const quote_validation: HTMLInputElement | null | undefined =
      document.getElementById('quote_validation') as HTMLInputElement;
    if (!quote_name || !quote_validation || !products) return;
    const location = JSON.parse(localStorage.getItem('location') || '{}');
    if (!quote_name.value) {
      quote_validation.classList.add('d-flex');
      quote_name.focus();
      return false;
    }
    quote_validation.classList.remove('d-flex');
    ///save resume info to use in other pages
    const products_data = {
      total: products.length,
      desc: concatProductsTitle(products),
    };
    const order_obj = {
      total,
      products_data,
      name: quote_name.value,
      products,
      zoneId: location.id === '0' || location.id === 0 ? 1 : location.id,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    ///saving cart as order in DB
    const collection = db.collection('quotes').doc(user.id);

    collection
      .collection('quotes')
      .add(order_obj)
      .then(() => {
        //delete cart from local storage
        localStorage.removeItem('cart');
        /// send to Orders page
        handleModal();
        window.location.href = '/cuenta/cotizaciones';
        return true;
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };
  const handleQtyChange = (ev: { currentTarget: HTMLButtonElement }) => {
    let product_id = ev.currentTarget.dataset.product;
    if (!product_id) return;
    product_id = product_id.replace('cart_', '');
    const type = ev.currentTarget.dataset.type;
    const products_data = [...products];
    const product_index = products_data.findIndex((i) => i.id === product_id);
    if (!products_data[product_index]) return;
    let value: number;
    if (type === 'input') {
      value = Number(ev.currentTarget.value);
    } else {
      value = products_data[product_index].cant;
      if (type === 'btn_plus') {
        value++;
      } else {
        value--;
      }
    }
    //avoid crashing by 0 or non numeric values
    if (!value || value < 1) return false;
    products_data[product_index].cant = value;
    setUpdateProduct(products_data[product_index]);
    setProducts(products_data);
    return false;
  };

  const cuponApply = async (ev: { currentTarget: HTMLButtonElement }) => {
    const button = ev.currentTarget;
    if (!button) return;
    const cupon_code_input = document.getElementById(
      'cupon_code'
    ) as HTMLInputElement;
    const cupon_code = cupon_code_input.value;
    if (!cupon_code) return false;
    button.classList.add('disabled');
    button.textContent = 'Aplicando...';

    const get_promotion = await getPromoCode(cupon_code.toUpperCase());
    if (get_promotion.error) {
      setError_message(get_promotion.error);
    } else {
      getProducts();
      setPromotion(get_promotion);
    }
    button.classList.remove('disabled');
    button.textContent = 'Aplicar';
  };

  const getPromoCode = async (code: string) => {
    return axios
      .post('scriptsCartPromotions', { code, user, products })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log('err', err);
        return { error: err };
      });
  };

  const removePromoCode = async (code: string) => {
    const collection = db.collection('cart').doc(user.id);
    await collection.update({
      promotion: firebase.firestore.FieldValue.delete(),
    });
    window.location.reload();
  };

  return (
    <div className="container first-container cart_page">
      <BreadcrumbSection />
      <h1>Carrito</h1>
      {loading ? (
        <div className="my-5">
          <Loader />
        </div>
      ) : products.length > 0 ? (
        <CartContent />
      ) : (
        <EmptyJumbotron
          heading="Sin productos en el carrito..."
          icon="fas fa-shopping-basket"
          text="No te quedes sin la casa de tus sueños"
          cta={{
            type: 'link',
            txt: 'conoce nuestro catálogo',
            to: '/',
            classes: 'btn-outline-dark my-3',
          }}
        />
      )}
      <SaveOrderModal
        toggle={handleModal}
        active={modalactive}
        saveOrder={saveOrder}
      />
      <Helmet>
        <script src={process.env.REACT_APP_RAPPI_URL} crossOrigin="anonymous" />
      </Helmet>
    </div>
  );
};

const BreadcrumbSection = () => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Link to="/">Home</Link>
    </BreadcrumbItem>
    <BreadcrumbItem active>
      <Link to="/cart">Carrito</Link>
    </BreadcrumbItem>
  </Breadcrumb>
);
export default CartPage;
