import React, { useState } from "react";
import { useParams } from "react-router";

import useProducts from "../../../hooks/useProducts";
import Loader from "../../common/loader";
import Products from "./products";
import { addProductCart } from "../../../middleware/cartFunctions";
import { AddProductModal } from "../../common/modals/modals";

const PromotionsPage = () => {
  const [modal, setModal] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const { brand_name } = useParams();
  const { result: products } = useProducts({
    brands: [brand_name],
  });

  const promptAddProduct = async (ev) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const product = products.find((p) => p.id === product_id);

    const product_amount = document.getElementById(
      `cant_input_list_${product_id}`
    ).value;
    product.amount = product_amount;
    setAddedProduct(product);
    await addProductCart({ product_amount, product_id, product_data: product });
    setModal(true);
  };
  const hideModal = () => {
    setModal(false);
  };

  return (
    <div className="container  first-container pt-4">
      <div className="product_list_section">
        <h3 className="underlined my-4 ">Productos de {brand_name}</h3>
        <div className="row">
          <div className="col-md-10 ">
            {products && products.length ? (
              <Products
                productsList={products}
                addProductCart={promptAddProduct}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      <AddProductModal
        active={modal}
        toggle={hideModal}
        product_title={addedProduct && addedProduct.descp1}
        type={
          addedProduct && addedProduct.xium
            ? addedProduct && addedProduct.xium.descrp
            : "pz"
        }
        neddedProducts_ids={addedProduct && addedProduct.ptrl}
        relatedTypes={addedProduct ? addedProduct.grpsbi[2] : null}
        product_amount={addedProduct.amount}
      />
    </div>
  );
};

export default PromotionsPage;
