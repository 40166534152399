import React, { useEffect, useState } from "react";
import { db } from "../../../middleware/firebase";
import Loader from "../../common/loader";
import EmptyJumbotron from "../../common/emptyJumbotron";

const Cards = () => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const collection = db.collection("users").doc(user.id).collection("cards");
  useEffect(() => {
    getcards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getcards = () => {
    collection
      .get()
      .then(function (querySnapshot) {
        const orders_obj = {};
        querySnapshot.forEach(function (doc) {
          const card = doc.data();
          orders_obj[doc.id] = card;
        });
        if (orders_obj) {
          setCards(orders_obj);
        } else {
          setCards(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Error getting documents: ", error);
      });
  };

  const deleteCard = async (ev) => {
    const { card: card_id } = ev.currentTarget.dataset;
    ev.currentTarget.classList.add("disabled");
    const current_cards = { ...cards };
    try {
      await db
        .collection("users")
        .doc(user.id)
        .collection("cards")
        .doc(card_id)
        .delete();
      delete current_cards[card_id];
      setCards({ ...current_cards });
    } catch (error) {
      console.log("cards.js:48 | error", error);
      ev.currentTarget.classList.remove("disabled");
    }
  };

  return (
    <div className="my_cards">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {loading && <Loader />}
          {!loading &&
            (cards ? (
              <CardsElm cards={cards} deleteCard={deleteCard} />
            ) : (
              <EmptyJumbotron
                icon="far fa-credit-card"
                heading="Sin Tarjetas"
                text="Aquí veras los métodos de pago que decidas guardar al hacer una compra online"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const CardsElm = ({ cards, deleteCard }) => (
  <div className="row cards">
    <div className="col-md-6">
      {Object.keys(cards).map((key) => {
        const card_item = cards[key];
        return (
          <div className="card mb-2" key={`card_${key}`}>
            <div className="card-body">
              <h5 className="card-title">{card_item.holder_name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Terminación:{" "}
                {card_item.last4 ||
                  card_item.card_number.substr(
                    card_item.card_number.length - 4
                  )}
              </h6>
              <p className="card-text">
                {card_item.brand} {card_item.expiration_month}{" "}
                {card_item.expiration_year}
              </p>
              <button
                className="btn btn-outline-danger ml-auto d-block"
                onClick={deleteCard}
                data-card={key}
              >
                Eliminar Tarjeta
              </button>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default Cards;
