import { useAsync } from "react-async-hook";
import { db } from "../middleware/firebase";
import { priceIva, getRegularPrice } from "../middleware/iva";
import { formatNumber } from "../middleware/common-functions";
import { IconItem, Product, renderIcon } from "./useProducts";

// function sleep(ms: number) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export type product = {
  price: string;
  zonePrice: string;
  price_num: number;
  aliasd: string;
};

export async function getProduct(productId: string) {
  let product = db.collection("products").doc(productId);
  const iconsRef = db.collection("icons");
  const set_icons: IconItem[] = [];
  const start = new Date();
  const snapshot = await iconsRef.where("valid", ">=", start).get();
  snapshot.forEach((doc) => {
    const icon_data = doc.data() as IconItem;
    icon_data.id = doc.id;
    if (
      typeof icon_data.from === "undefined" ||
      icon_data.from.toDate() >= start
    ) {
      return;
    }
    set_icons.push(icon_data);
  });

  return product.get().then((doc) => {
    if (doc.exists) {
      const product_doc = (doc.data() as Product) || {};
      if (product_doc) {
        product_doc.icon = renderIcon(product_doc, set_icons);
      }
      product_doc.price_num = priceIva(
        product_doc.zonePrice,
        product_doc.price
      );

      product_doc.price = formatNumber(product_doc.price_num);
      product_doc.price_regular = getRegularPrice(
        product_doc.zonePrice,
        product_doc.price_num
      );
      return product_doc;
    } else {
      return false;
    }
  });
}

export default function useProduct(productId: string) {
  return useAsync(() => getProduct(productId), [productId]);
}
