import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { db } from '../../../middleware/firebase';
import SuccessSection from './success';
import { OrderType } from '../../../models/order_model';
import Page404 from '../page404';
import Loader from '../../common/loader';

const ThankyouPage: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { order_id } = useParams<{ order_id?: string }>();
  const [orderData, setOrderData] = useState<OrderType | null | false>(null);

  useEffect(() => {
    getOrder();
  }, [order_id]);

  const getOrder = () => {
    const collection = db
      .collection('orders')
      .doc(user.id)
      .collection('orders')
      .doc(order_id);
    collection.get().then((doc) => {
      if (!doc.exists) {
        console.log('No such document!');
        setOrderData(false);
      } else {
        const data = doc.data() as OrderType;
        setOrderData(data);
      }
    });
  };

  if (orderData === null) {
    return (
      <div className="container-fuid checkout_page">
        <Loader />
      </div>
    );
  } else if (orderData === false) {
    return (
      <div className="container-fuid checkout_page">
        <Page404 from="checkout" />
      </div>
    );
  }

  return (
    <div className="container checkout_page">
      <SuccessSection
        totals={orderData.totals}
        delivery_type={orderData.delivery_type}
        active_branch={
          orderData.selectedBranch ? orderData.selectedBranch : undefined
        }
        delivery_address={
          orderData.address
            ? `${orderData.address} ${orderData.address.city}`
            : orderData.shipping.branch.address!
        }
        payment_type={orderData.payment_type}
        pay_id={orderData.ipgTransactionId}
        shippingcost={orderData.shipping ? orderData.shipping : undefined}
      />
    </div>
  );
};

export default ThankyouPage;
