import React from 'react';

import Main from './main';
import Checkout from './checkout';

const Layouts = (props) => {
  let layout = false;
  if (
    window.location.pathname === '/checkout' ||
    window.location.pathname.includes('gracias')
  ) {
    layout = 'checkout';
  }
  if (layout === 'checkout') {
    return <Checkout>{props.children}</Checkout>;
  }
  return <Main>{props.children}</Main>;
};

export default Layouts;
