import React from "react";

const Footer = () => (
  <footer className="footer_checkout">
    <div className="container">
      <div className="legal ">
        <a href="/info/terminosycondiciones">Términos y Condiciones</a>
        <a href="/info/aviso">Aviso de Privacidad</a>
      </div>
    </div>
  </footer>
);

export default Footer;
