import React, { useState } from 'react';

import { InCartProductDesc } from '../../common/products/productBlock';
import { CartProductItem, ShppingResponse } from '../../../models/cart_model';
import { DeliveryTypes } from '../../../models/checkout_model';

type ShippingCostsProps = {
  products: CartProductItem[];
  removeFun: any;
  shippingcost: ShppingResponse;
  delivery_type: DeliveryTypes;
  changePriceAlert: any;
  disableButton: any;
  goToAddress: any;
  zip: string;
};
const ShippingCosts: React.FC<ShippingCostsProps> = ({
  products,
  removeFun,
  shippingcost,
  delivery_type,
  changePriceAlert,
  zip,
  disableButton,
  goToAddress,
}) => {
  let in_stock;
  let no_stock;
  let no_delivery: CartProductItem[] | false = false;
  const shipping_price = shippingcost.shippingCosts.shipping_cost;
  if (shippingcost.shippingCosts.in_stock.products_ids) {
    in_stock = products.filter((item) =>
      shippingcost.shippingCosts.in_stock.products_ids.find(
        (i) => i === item.id
      )
    );
    // setProducts_stock(in_stock);
  }

  if (shippingcost.shippingCosts.no_stock) {
    no_stock = shippingcost.shippingCosts.no_stock.map((no_stock_item) =>
      products.filter((item) =>
        no_stock_item.products_ids.find((i) => i === item.id)
      )
    );
    // setProducts_no_stock(no_stock);
  }

  if (shippingcost.shippingCosts.no_delivery.products_ids) {
    no_delivery = products.filter((item) =>
      shippingcost.shippingCosts.no_delivery.products_ids.find(
        (i) => i === item.id
      )
    );
    // setProducts_no_stock(no_stock);
  }

  if (no_delivery && no_delivery.length) {
    disableButton(true);
  }
  return (
    <div className=" shipping_cost">
      {changePriceAlert && <PriceAlert />}
      {!changePriceAlert && zip ? (
        <ShippingCostAlert shipping_price={shipping_price} zip={zip} />
      ) : null}
      {no_delivery && no_delivery.length > 0 ? (
        <NoDeliveryCTA clickFun={removeFun} goToAddress={goToAddress} />
      ) : null}
      {no_delivery && no_delivery.length > 0 ? (
        <PackageCard
          products={no_delivery}
          type="no_delivery"
          delivery_type={delivery_type}
          delivery_date={shippingcost.shippingCosts.in_stock.delivery_date}
        />
      ) : null}
      {in_stock && in_stock.length > 0 ? (
        <PackageCard
          products={in_stock}
          type="in_stock"
          delivery_type={delivery_type}
          delivery_date={shippingcost.shippingCosts.in_stock.delivery_date}
        />
      ) : null}
      {no_stock && no_stock.length > 0
        ? no_stock.map((item, key) => (
            <PackageCard
              products={item}
              type="no_stock"
              delivery_type={delivery_type}
              key={`no_stock_card_${key}`}
              delivery_date={
                shippingcost.shippingCosts.no_stock[key].delivery_date
              }
            />
          ))
        : null}
    </div>
  );
};

const ShippingCostAlert: React.FC<{ zip: string; shipping_price: number }> = ({
  zip,
  shipping_price,
}) => {
  return (
    <div className="alert bg_gray">
      {shipping_price ? (
        <p>
          El costo del envío al CP {zip} es de ${shipping_price}
        </p>
      ) : (
        <p>Envío gratis al CP {zip}</p>
      )}
    </div>
  );
};
const PriceAlert = () => (
  <div className={`alert alert-danger`}>
    <h3>
      {' '}
      Los precios de tu carrito cambiaron{' '}
      <i className="fas fa-exclamation-triangle"></i>
    </h3>
    <p>
      Esto se debe a que los precios de los productos o el IVA de la sucursal
      que tienes seleccionada no son iguales al destino de tu pedido. <br />
      Revisa la información antes de continuar.
    </p>
  </div>
);

const PackageCard: React.FC<{
  products: CartProductItem[];
  delivery_date: string;
  type: 'no_delivery' | 'no_stock' | 'in_stock';
  delivery_type: DeliveryTypes;
}> = ({ products, delivery_date, type, delivery_type }) => {
  const [is_open, setOpen] = useState(false);
  return (
    <div className="accordion">
      <div className="card mb-4 product_cart">
        <div
          className={`card-header d-flex justify-content-between ${
            type === 'no_delivery' && 'bg-danger text-white'
          }`}
        >
          {renderTitle(type, delivery_date, delivery_type, products.length)}
          <button
            className="btn btn-transparent text-capitalize"
            onClick={() => {
              setOpen(!is_open);
            }}
          >
            Ver Detalle{' '}
            <i
              className={`fas  ml-2 ${
                is_open ? 'fa-angle-up' : 'fa-angle-down'
              }`}
            ></i>
          </button>
        </div>

        <div
          className={`collapse ${is_open ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body">
            {type === 'no_delivery' && (
              <p>
                Los siguientes productos en tu carrito no pueden ser enviados ya
                que el costo de envío es muy elevado.{' '}
              </p>
            )}
            {products.map((product) => (
              <InCartProductDesc
                key={product.id}
                product={product}
                price={true}
                small
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const NoDeliveryCTA: React.FC<{ clickFun: any; goToAddress: any }> = ({
  clickFun,
  goToAddress,
}) => (
  <div className="no_delivery alert alert-warning">
    <h4 className="alert-heading">Atención</h4>
    Hay productos en tu carrito que no pueden ser enviados, ¿qué deseas hacer?
    <hr />
    <div className="d-flex">
      <button
        className="btn btn-outline-info"
        value="address"
        onClick={goToAddress}
        data-next="shipping"
      >
        Cambiar de Dirección
      </button>
      <button
        className="btn btn-dark ml-4"
        onClick={clickFun}
        data-next="payment"
      >
        Remover estos productos de mi carrito
      </button>
    </div>
  </div>
);

function renderTitle(
  type: 'no_delivery' | 'no_stock' | 'in_stock',
  delivery_date: string,
  delivery_type: DeliveryTypes,
  products_length: number
) {
  const product_txt = products_length > 1 ? 'Products' : 'Producto';
  if (type === 'no_delivery') {
    return (
      <h6 className="m-0">
        <i className="fas fa-exclamation-triangle mr-3"></i>Estos productos no
        pueden ser enviados
        <small className="ml-2">
          <i className="fas fa-box-open"></i>{' '}
          {`${products_length} ${product_txt}`}
        </small>
      </h6>
    );
  }
  if (delivery_type === 'shipping')
    return (
      <h6 className="m-0">
        Llega entre el {delivery_date}{' '}
        <small className="ml-2">
          <i className="fas fa-box-open"></i>{' '}
          {`${products_length} ${product_txt}`}
        </small>
      </h6>
    );
  if (type === 'no_stock')
    return (
      <h6 className="m-0">
        Disponible para {delivery_date}{' '}
        <small className="ml-2">
          <i className="fas fa-box-open"></i>{' '}
          {`${products_length} ${product_txt}`}
        </small>
        <small className="ml-3">
          <i className="far fa-bell"></i> Te avisarémos
        </small>
      </h6>
    );
  return (
    <h6 className="m-0">
      {' '}
      Disponible Ahora{' '}
      <small className="ml-2">
        <i className="fas fa-box-open"></i>{' '}
        {`${products_length} ${product_txt}`}
      </small>
    </h6>
  );
}

export default ShippingCosts;
