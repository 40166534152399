import React from 'react';
import { Helmet } from 'react-helmet';

const Beetrack = () => {
  return (
    <>
      <div className="bee_track_img">
        <div className="container text-center">
          <img src="https://cdn.mathasa.mx/banners/widgetbeetrack.png" />
        </div>
      </div>
      <iframe
        name="beetrack-widget"
        id="beetrack-widget"
        frameBorder="0"
        width="100%"
        height="310px"
        src="https://mathasa.dispatchtrack.com/widget/C_y_rPBfXmxuSA7MLOkHpg"
      />
    </>
  );
};

export default Beetrack;
