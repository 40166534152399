import axios from 'axios';
import { OrderType } from '../models/order_model';
import { LineItem, CheckoutData, ShipTo } from '../models/coppel';
import backendServer from './axios';
const baseURL = process.env.REACT_APP_CPLPY_ENDPOINT;

export const coppelInstance = axios.create({
  baseURL,
});

declare global {
  interface Window {
    CPLPY: any;
  }
}

// type LineItemsType = {
//   unitPrice: number;
//   totalAmount: number;
//   productSKU?: string;
//   quantity: number;
//   productCode: number;
//   productDescription?: string;
//   productName: string;
// };

function makeid(length: number) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export async function authenticateCoppel(): Promise<null | string> {
  try {
    const response = await backendServer.post('/scriptsPaymentsCoppelAuth');
    console.log('coppel.ts:37 | ', response);
    return response.data.token;
  } catch (error) {
    console.log('coppel.ts:37 | error ', error);
    return null;
  }
}

export async function getPurchaseRequest(
  config: {
    userId: string;
    price: number;
    orderDescription: string;
    order_obj: OrderType;
  },
  token: string
): Promise<[null | { paymentRequest: string; orderId: string }, string?]> {
  const { userId, price, orderDescription, order_obj } = config;
  const orderId = makeid(10);
  const transaccion = `${userId}__${orderId}`;
  const ip_address = localStorage.getItem('location_ip') || '';
  const lineItems: LineItem[] = order_obj.products.map((product) => ({
    totalAmount: product.price * product.cant,
    unitPrice: product.price,
    quantity: product.cant,
    productSKU: product.idpart,
    productDescription: product.title,
    productName: product.title,
    productCode: product.id,
  }));

  const shipTo: ShipTo = {
    address1:
      order_obj.delivery_type === 'shipping'
        ? order_obj.address!.address!
        : order_obj.selectedBranch!.address!,
    address2: '',
    country: 'MEX',
    destinationTypes: 'D',
    firstName: order_obj.user!.name || order_obj.user!.displayName!,
    lastName: order_obj.user!.lastname || order_obj.user!.displayName!,
    phoneNumber: order_obj.user!.phone!,
    postalCode:
      order_obj.delivery_type === 'shipping' ? order_obj.address!.zip! : '0000',
    method: order_obj.delivery_type,
  };

  const checkoutData: CheckoutData = {
    transaccion,
    monto: price,
    descripcion: orderDescription,
    ipAddress: ip_address,
    informacionCompra: {
      shipTo,
      lineItems,
    },
  };
  try {
    const res = await coppelInstance.post(
      '/purchase-request/online',
      checkoutData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);
    if (res.data.code !== 'SUCCESS') {
      return [null, 'Error: ' + res.data.message];
    }
    return [{ paymentRequest: JSON.parse(res.data.data).solicitud, orderId }];
  } catch (error) {
    console.log(error);
    return [null, 'Ocurrió un error inesperado.'];
  }
}

function isCoppelReady() {
  if (window.CPLPY) {
    const blackbox = window.CPLPY.blackbox;
    const token = window.CPLPY.token;
    // document.getElementById('ModalActionBtn')?.classList.add('d-block');
    if (blackbox && token) return { blackbox, token };
    return false;
  }
  return false;
}

export async function initiatePayWithCoppel(config: {
  userId: string;
  price: number;
  orderDescription: string;
  setShowCoppelPayModal: (b: boolean) => void;
  order_obj: OrderType;
}): Promise<[null | { paymentRequest: string; orderId: string }, string?]> {
  const { setShowCoppelPayModal, ...restConfig } = config;
  setShowCoppelPayModal(true);
  const coppel_token = await authenticateCoppel();
  console.log('coppel.ts:135 | coppel_token', coppel_token);
  if (!coppel_token) {
    return [null, 'Lo sentimos, hubo un problema con Coppel Pay.'];
  }
  const [data, error] = await getPurchaseRequest(restConfig, coppel_token);
  if (error) {
    alert(error);
    setShowCoppelPayModal(false);
  }
  if (data && data.paymentRequest) {
    window.CPLPY.init(data.paymentRequest);
    // if (process.env.REACT_APP_ENV !== 'production') {
    //   window.CPLPY.sandbox = true;
    // }
    window.CPLPY.open();
    return [data];
  }
  return [null, error || 'Ocurrió un error inesperado.'];
}

export async function finalizePayWithCoppel(paymentRequest: string) {
  const coppel = isCoppelReady();
  console.log('coppel.ts:99 | coppel', coppel);
  if (coppel) {
    const ip_address = localStorage.getItem('location_ip') || '';
    const { token } = coppel;
    const data = {
      cpl_solicitud: paymentRequest,
      cpl_token: token,
      cpl_ip: ip_address,
    };
    try {
      const res = await coppelInstance.post('/v2/confirmacion-pago', data);
      debugger;
      if (res.data.code !== 'SUCCESS') {
        return [null, 'Error: ' + res.data.message];
      }
      return [JSON.parse(res.data.data)];
    } catch (error) {
      console.log(error);
      return [null, 'Ocurrió un error inesperado.'];
    }
  } else {
    return [null, 'Lo sentimos, hubo un problema con Coppel Pay.'];
  }
}
