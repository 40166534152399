import React from "react";
import states from "../../../data/states.json";
import states_us from "../../../data/states_us.json";
import { UserDeliveryData } from "../../../models/user_model";

type AddressComponentProps = {
  deliveryData: UserDeliveryData;
  handleDelivery: any;
  is_card?: boolean;
};

type PaymentAddressProps = {
  paymentAddress: boolean;
  handlePaymentAddressSelection: any;
};

export const AddressComponent: React.FC<AddressComponentProps> = ({
  deliveryData,
  handleDelivery,
  is_card,
}) => (
  <div className={`address_container ${deliveryData.prev && "d-none"}`}>
    <div className={`form-row ${deliveryData.prev || (is_card && "d-none")}`}>
      <div className="form-group col-md-6">
        <label>Nombre</label>
        <input
          value={deliveryData.name}
          data-type="name"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Apellidos</label>
        <input
          value={deliveryData.lastname}
          data-type="lastname"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
    </div>
    <div className={`form-row ${deliveryData.prev && "d-none"}`}>
      <div className={`form-group col-md-2 `}>
        <label htmlFor="inputAddress">Teléfono</label>
        <select
          className="form-control"
          onChange={handleDelivery}
          data-type="phone_code"
          defaultValue="MX"
        >
          <option value="MX">🇲🇽</option>
          <option value="USA">🇺🇸</option>
        </select>
      </div>
      <div className={`form-group col-md-10`}>
        <label htmlFor="inputAddress" className="opacity_0">
          Teléfono
        </label>
        <input
          value={deliveryData.phone}
          data-type="phone"
          onChange={handleDelivery}
          type="number"
          className="form-control"
          placeholder="Incluir Lada"
        />
      </div>
    </div>
    <div className={`form-row ${!is_card && "d-none"}`}>
      <div className={`form-group col-md-6 `}>
        <label htmlFor="inputAddress">País</label>
        <select
          className="form-control"
          onChange={handleDelivery}
          data-type="countrycode"
          defaultValue="MX"
        >
          <option value="MX">México</option>
          <option value="USA">United States</option>
        </select>
      </div>
    </div>
    <div className={`form-row ${deliveryData.prev && "d-none"}`}>
      <div className="form-group col-md-7">
        <label>Calle</label>
        <input
          value={deliveryData.street}
          data-type="street"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
      <div className={`form-group ${deliveryData.prev && "d-none"} col-md-5`}>
        <label>Número Exterior</label>
        <input
          value={deliveryData.numext}
          data-type="numext"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
    </div>
    <div className={`form-row ${deliveryData.prev && "d-none"}`}>
      <div className="form-group col-md-7">
        <label htmlFor="inputPassword4">Colonia</label>
        <input
          value={deliveryData.colonia}
          data-type="colonia"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
      <div className={`form-group ${deliveryData.prev && "d-none"} col-md-5`}>
        <label htmlFor="inputPassword4">
          Número Interior <small>(Opcional)</small>
        </label>
        <input
          value={deliveryData.numint}
          data-type="numint"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
    </div>
    <div className={`form-row ${deliveryData.prev && "d-none"}`}>
      <div className="form-group col-md-8">
        <label htmlFor="inputCity">Ciudad</label>
        <input
          value={deliveryData.city}
          data-type="city"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
      <div className={`form-group ${deliveryData.prev && "d-none"} col-md-4`}>
        <label htmlFor="inputPassword4">CP</label>
        <input
          value={deliveryData.zip}
          data-type="zip"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
    </div>
    <div className={`form-row ${deliveryData.prev && "d-none"}`}>
      <div className={`form-group ${deliveryData.prev && "d-none"} col-md-6`}>
        <label htmlFor="inputPassword4">Municipio / Delegación </label>
        <input
          value={deliveryData.municipio}
          data-type="municipio"
          onChange={handleDelivery}
          type="text"
          className="form-control"
        />
      </div>
      <div className={`form-group col-md-6 ${deliveryData.prev && "d-none"}`}>
        <label htmlFor="inputState">Estado</label>
        <select
          className="form-control"
          onChange={handleDelivery}
          data-type="state"
          defaultValue="false"
        >
          <option value="false">Choose...</option>
          {deliveryData.countrycode && deliveryData.countrycode === "USA"
            ? states_us.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))
            : states.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
        </select>
      </div>
    </div>
    <div className={`form-group ${is_card && "d-none"}`}>
      <label htmlFor="inputState">Comentarios (opcional)</label>
      <textarea
        data-type="message"
        onChange={handleDelivery}
        defaultValue={deliveryData.message}
        className="form-control"
      ></textarea>
    </div>
  </div>
);

export const PaymentAddress: React.FC<PaymentAddressProps> = ({
  paymentAddress,
  handlePaymentAddressSelection,
}) => (
  <div className="select_address mb-3">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value="false"
        id="same_address"
        checked={!paymentAddress}
        onClick={handlePaymentAddressSelection}
      />
      <label className="form-check-label" htmlFor="same_address">
        Misma Dirección de Envío
      </label>
    </div>
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value="true"
        id="not_same_address"
        checked={Boolean(paymentAddress)}
        onClick={handlePaymentAddressSelection}
      />
      <label className="form-check-label" htmlFor="not_same_address">
        Usar una dirección diferente
      </label>
    </div>
    <hr />
  </div>
);
