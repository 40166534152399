import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth-context";
const Account = () => {
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [userInfo, setUserInfo] = useState(false);
  const logOutHandler = () => {
    authContext.logoutFun();
  };

  useEffect(() => {
    const getUserInfo = {
      name: {
        value: user.displayName,
        title: "Nombre",
      },
      email: {
        value: user.email,
        title: "Email",
      },
    };
    setUserInfo(getUserInfo);
  }, []);

  return (
    <div className="my_account">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {Object.keys(userInfo).map((key) => {
            const item = userInfo[key];
            return (
              <div className="form-group">
                <label htmlFor={`input_${key}`}>{item.title} </label>
                <input
                  type="text"
                  className="form-control form-control-plaintext"
                  defaultValue={item.value}
                  id={`input_${key}`}
                  aria-describedby="emailHelp"
                />
              </div>
            );
          })}
        </div>
      </div>
      <button
        className="btn btn-danger float-right mb-3"
        onClick={logOutHandler}
      >
        Cerrar Sesión
      </button>
    </div>
  );
};

export default Account;
