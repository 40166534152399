import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ItemsNav from './Nav';
import ItemsNavDesign from './NavDesign';
import MobNav from './mobNav';
import no_img from '../../assets/no_image.jpg';
import axios from '../../middleware/axios';
import { BarLoader } from 'react-spinners';

import { getLogo, isDesign } from '../../middleware/common-functions';

const SearchNav = () => {
  const [toggled, settoggled] = useState(true);
  const [toggled_b, settoggled_b] = useState(false);
  const [toggledFirst, settoggledFirst] = useState(false);
  const [toggledMob, settoggledMob] = useState(false);
  const [toggledMobSearch, settoggledMobSearch] = useState(false);
  const [cart_items, setcart_items] = useState(0);
  const [searchProducts, setSearchProducts] = useState(null);
  const [assignedClasses, setassignedClasses] = useState([
    'navbar',
    'navbar-expand-md',
    'material-shadows',
    'fixed-top',
    'main_navbar',
    'pb-md-0',
    'scroll',
  ]);
  const logo = getLogo();
  const is_design = isDesign();

  useEffect(() => {
    const cart_items_local = JSON.parse(localStorage.getItem('cart'));
    if (cart_items_local) {
      setcart_items(cart_items_local.length);
    }
    ///uncomment to make transition
    // this.updatesize();
    // /// top nav animation
    document.getElementsByTagName('body')[0].onscroll = () => {
      updatesize();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatesize = () => {
    const current_assignedClasses = [...assignedClasses];
    let scroll_value =
      document.documentElement.scrollTop || document.body.scrollTop;
    scroll_value =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (
      (!toggledFirst && scroll_value >= 20) ||
      (scroll_value > 20 && toggled_b)
    ) {
      current_assignedClasses.push('top');
      settoggled_b(false);
      settoggled(true);
      settoggledFirst(true);
      setassignedClasses(current_assignedClasses);
    } else if (scroll_value <= 20 && toggled) {
      current_assignedClasses.pop();

      settoggled_b(true);
      settoggled(false);
      setassignedClasses(current_assignedClasses);
    }
  };

  const toggleMobNav = () => {
    const current_toggle = toggledMob;
    settoggledMob(!current_toggle);
    settoggledMobSearch(false);
  };

  const toggleMobSeachFun = () => {
    const current_toggle = toggledMobSearch;
    if (!current_toggle) {
      setTimeout(() => {
        document.getElementById('searchInputMob').focus();
      }, 100);
    }
    settoggledMobSearch(!current_toggle);
  };

  const searchProductsPromt = () => {
    let text = document.getElementById('searchInput').value;
    if (!text) {
      text = document.getElementById('searchInputMob').value;
      if (!text) {
        return;
      }
      document.getElementById('searchInputMob').blur();
    }
    setSearchProducts('loading');

    axios
      .get(`scriptsProductsSearch?keywords=${text}`)
      .then((response) => {
        setSearchProducts(response.data);
      })
      .catch((err) => console.log('err', err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchProductsPromt();
    }
  };

  const removeSearchProducts = () => {
    setSearchProducts(null);
  };

  return (
    <React.Fragment>
      <div
        className={`backdrop_nav  ${searchProducts && 'active'}`}
        onClick={removeSearchProducts}
      ></div>
      <nav className={assignedClasses.join(' ')} id="main_navbar">
        <div className="container nav_container ">
          <button
            className="btn d-md-none"
            type="button"
            onClick={toggleMobSeachFun}
          >
            <i className="fas fa-search"></i>
          </button>
          <Link className="navbar-brand smooth_scroll" to="/">
            <img src={logo} className="img-fluid" alt="Logo Mathasa" />
          </Link>
          <Link to="/cart" className="btn btn-cart d-md-none">
            <i className="fas fa-shopping-cart "></i>{' '}
            <span id="cart_items_cant_mob">{cart_items}</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
            onClick={toggleMobNav}
          >
            <i className="fas fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="row w-100">
              <div className="col-6 search_results_container">
                <SearchBar
                  searchProductsPromt={searchProductsPromt}
                  handleKeyDown={handleKeyDown}
                />
                {searchProducts && <SearchResult products={searchProducts} />}
              </div>
              <div className="col-6 text-right">
                <Link to="/cart" className="btn btn-cart">
                  <i className="fas fa-shopping-cart"></i>{' '}
                  <span id="cart_items_cant">{cart_items}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {is_design ? <ItemsNavDesign /> : <ItemsNav />}
        </div>
        <MobNav is_active={toggledMob} toggleMobNav={toggleMobNav} />
        <SearchMob
          is_active={toggledMobSearch}
          searchProductsPromt={searchProductsPromt}
          handleKeyDown={handleKeyDown}
          products={searchProducts}
        />
      </nav>
    </React.Fragment>
  );
};

const SearchMob = ({
  searchProductsPromt,
  handleKeyDown,
  products,
  is_active,
}) => {
  if (!is_active) {
    return null;
  }
  return (
    <div className="mob_search_bar search_results_container d-md-none">
      <SearchBar
        searchProductsPromt={searchProductsPromt}
        handleKeyDown={handleKeyDown}
        is_mob
      />
      {products && <SearchResult products={products} />}
    </div>
  );
};

const SearchBar = ({ searchProductsPromt, handleKeyDown, is_mob }) => {
  return (
    <div className="input-group">
      <input
        type="text"
        id={is_mob ? 'searchInput' : 'searchInput'}
        className="form-control"
        placeholder="Busca Productos"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        onKeyDown={handleKeyDown}
      />
      <div className="input-group-append">
        <button
          className="btn btn-light search_bar"
          type="button"
          onClick={searchProductsPromt}
        >
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
};

const SearchResult = ({ products }) => (
  <div className="search_results">
    {products === 'loading' ? (
      <div className="loading">
        {' '}
        <BarLoader />
      </div>
    ) : products.length > 0 ? (
      products.map((product) => (
        <SearchProducts key={`result_${product.id}`} product={product} />
      ))
    ) : (
      <p className="no_results">
        <i className="fas fa-search"></i> Sin Resultados
      </p>
    )}
  </div>
);

const SearchProducts = ({ product }) => (
  <div className="search_item product">
    <a href={`/product/${product.id}`}></a>
    <img
      src={product.img || no_img}
      className="img-fluid"
      alt={product.title}
    />
    <div className="desc">
      <h4>{product.title}</h4>
      <p>{product.aliasd}</p>
    </div>
  </div>
);

export default SearchNav;
