import React from "react";

import useProducts from "../../../hooks/useProducts";
import NeddedProduct from "../../common/products/neddedProducts";
import { priceIva } from "../../../middleware/iva";
import {
  getRandomNumber,
  formatNumber,
} from "../../../middleware/common-functions";

const RelatedProducts = ({ cart_products, updateCart }) => {
  const related_types = cart_products
    .map((item) => {
      const type = item.details.find((i) => i.name == "Tipo");
      return type ? type.value : null;
    })
    .filter((i) => i);

  const rand = getRandomNumber(0, related_types.length - 1);
  const { result: products } = useProducts({
    grpsbi: [related_types[rand]],
    limit: 6,
  });
  return (
    <div className="products_needed">
      <h3>Productos Relacionados</h3>
      {products &&
        Object.keys(products).map((key) => {
          const item = products[key];
          item.price_num = priceIva(item.zonePrice);
          item.price = formatNumber(item.price_num);

          return (
            <NeddedProduct
              product={item}
              type="related"
              key={item.id}
              updateCart={updateCart}
            />
          );
        })}
    </div>
  );
};

export default RelatedProducts;
