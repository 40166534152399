import React, { useEffect, useState } from "react";
import { getPost } from "../../../middleware/wordpress_api";
import wordrpess_posts from "../../../data/wordpress_post.json";
import { useParams } from "react-router";
import Beetrack from "./Beetrack"

const StaticPages = () => {
  const [pageInfo, setPageInfo] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const { post_name } = useParams();
  useEffect(() => {
    const post_id = getPostId(post_name);
    getPost(post_id.id).then((data) => {
      setPageInfo(data.content.rendered);
      setPageTitle(data.title.rendered);
    });
  }, [post_name]);

  return (
    <div className="first-container container pt-5 static">
      <h1>{pageTitle}</h1>
      {post_name == "entregas" && <Beetrack/>}
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: pageInfo,
        }}
      ></div>
    </div>
  );
};

function getPostId(post_name) {
  return wordrpess_posts.find((item) => item.title === post_name);
}

export default StaticPages;
