import React, { useState, useEffect } from "react";
import AddProduct from "./addProduct";
import { addProductCart } from "../../../middleware/cartFunctions";
import { formatNumber } from "../../../middleware/common-functions";
import no_img from "../../../assets/no_image.jpg";

const NeddedProduct = ({ product, product_amount = 1, type, updateCart }) => {
  const [amount, setAmount] = useState(product_amount);
  const [added, setAdded] = useState(false);
  useEffect(() => {
    setAmount(product_amount);
  }, [product_amount]);

  const promtAddProduct = async (ev) => {
    const product_amount = document.getElementById(
      `cant_input_related_${product.id}`
    ).value;
    await addProductCart({
      product_amount: product_amount,
      product_id: product.id,
      product_data: product,
    });
    updateCart();
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 5500);
  };
  return (
    <div className="product_related popup mb-2">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-4 col-md-2">
              <img
                src={
                  product.images && product.images.length > 0
                    ? product.images[0]
                    : no_img
                }
                alt={product.descp1}
                className="img-fluid"
              />
            </div>
            <div className="col-8 col-md-6">
              <a href={`/product/${product.id}`}>{product.descp1}</a> <br />
              {type === "needed" && (
                <small>Cantidad sugerida: {product_amount}</small>
              )}{" "}
              <br />
              Precio: ${product.price}
              {amount && (
                <React.Fragment>
                  <br /> Total: $
                  {formatNumber(product.price_num * amount, true)}
                </React.Fragment>
              )}
            </div>
            <div className="col col-md-4 price">
              <AddProduct
                promptAdd={promtAddProduct}
                added={added}
                cant_hidd
                product_id={product.id}
                from="related"
                unit={product.xium ? product.xium.descrp : "pz"}
                block
                suggest_amount={product_amount}
              />
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeddedProduct;
