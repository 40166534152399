import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../../context/auth-context";
import { capitalize } from "../../../middleware/common-functions";

const benefits = [
  "Verifica el estado de tus compras",
  "Recibe promociones muy atractivas",
  "Compra con menos clicks",
];

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const email = useRef(false);
  const phone = useRef(false);
  const password = useRef(false);
  const user_name = useRef(false);
  const user_lastname = useRef(false);
  const birthdate = useRef(false);
  //date from 5 years ago
  const min_birthdate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 5)
  )
    .toISOString()
    .split("T")[0];
  const signUpHandler = () => {
    if (
      !email.current.value ||
      !phone.current.value ||
      !password.current.value ||
      !user_name.current.value ||
      !user_lastname.current.value
    )
      return false;
    const display_name = `${capitalize(user_name.current.value)} ${capitalize(
      user_lastname.current.value
    )}`;
    authContext.signUpFun(
      email.current.value,
      phone.current.value,
      password.current.value,
      display_name,
      birthdate.current.value
    );
  };

  return (
    <div className="container first-container last-container">
      <h2 className="underlined my-4 py-2">Crea tu Cuenta</h2>
      <div className="row justify-content-center my-5">
        <div className="col-md-4">
          <div className="form-row">
            <div className="col form-group">
              <label>Nombre</label>
              <input type="text" className="form-control" ref={user_name} />
            </div>
            <div className="col form-group">
              <label>Apellido</label>
              <input type="text" className="form-control" ref={user_lastname} />
            </div>
          </div>
          <div className="form-group">
            <label>Teléfono de Contacto</label>
            <input
              type="tel"
              className="form-control"
              ref={phone}
              autoComplete="new-password"
            />
            <small className="form-text text-muted">
              Sin Spam, queremos avisarte del estatus de tus pedidos
            </small>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" ref={email} />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              ref={password}
              autoComplete="new-password"
            />
          </div>
          <div className="form-group">
            <label>Cumpleaños</label>
            <input
              type="date"
              className="form-control"
              ref={birthdate}
              max={min_birthdate}
            />
            <small className="form-text text-muted">
              Quremos sorprenderte, descuida, no le diremos a nadie
            </small>
          </div>
          <button
            className="btn btn-dark btn-block mb-3"
            onClick={signUpHandler}
          >
            Crear Cuenta
          </button>
          <small className="d-block text-center">
            ¿Ya tienes cuenta? <br />
            <Link to="/login">Inicia Sesión</Link>
          </small>
        </div>
        <div className="col-md-4 border-left">
          <h4>Que puedes hacer con tu cuenta</h4>
          <ul>
            {benefits.map((item, key) => (
              <li key={`benefits_${key}`}>
                <i className="fas fa-check text-success mr-2"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
