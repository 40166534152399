import React, { useContext, useEffect, useRef, useState } from 'react';

import { UserType } from '../../../models/user_model';
import { AuthContext } from '../../../context/auth-context';

type NoDataInfoProps = {
  handleUserData: any;
  user_data: UserType;
};

type PasswordCompProps = {
  password: React.RefObject<HTMLInputElement>;
  error: any;
};

const NoDataInfo: React.FC<NoDataInfoProps> = ({
  handleUserData,
  user_data,
}) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const email = useRef<HTMLInputElement>(null);
  const phone = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const loginHandler = () => {
    if (!email?.current?.value || !password?.current?.value) return false;
    setLoading(true);
    authContext.loginFun(
      email.current.value,
      password.current.value,
      'email',
      true
    );
  };

  const promptHandler = () => {
    const prompt = !loginPrompt;
    ///focus on email input
    if (prompt) {
      email.current?.focus();
    }
    setLoginPrompt(prompt);
  };

  useEffect(() => {
    if (authContext && authContext.errors) {
      setLoading(false);
    }
  }, [authContext]);

  return (
    <div className="user_email">
      {user_data.isAnonymous ? (
        loginPrompt ? null : (
          <button
            className="btn btn-info text-capitalize py-3 px-5 w-100 "
            onClick={promptHandler}
          >
            Ya Tengo Cuenta
          </button>
        )
      ) : null}

      <hr />
      <div className={`form-row ${!user_data.isAnonymous ? 'd-none' : ''}`}>
        <div className="col">
          <div className="form-group">
            <label>Nombre</label>
            <input
              type="text"
              className="form-control"
              aria-describedby="textHelp"
              onChange={handleUserData}
              id="name"
              value={user_data.name}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label>Apellido</label>
            <input
              type="text"
              className="form-control"
              aria-describedby="textHelp"
              onChange={handleUserData}
              value={user_data.lastname}
              id="lastname"
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>Teléfono de Contacto</label>
        <input
          type="phone"
          id="phone"
          placeholder="Incluye lada y código de país"
          className="form-control"
          aria-describedby="phoneHelp"
          onChange={handleUserData}
          value={user_data.phone || ''}
          ref={phone}
        />
        <small id="emailHelp" className="form-text text-muted">
          Para servirte y brindarte cambios en el estatus de tu pedido.
        </small>
      </div>
      <div className={`form-group ${!user_data.isAnonymous ? 'd-none' : ''}`}>
        <label>Email</label>
        <input
          type="email"
          id="email"
          className="form-control"
          aria-describedby="emailHelp"
          onChange={handleUserData}
          value={user_data.email || ''}
          ref={email}
        />
        <small id="emailHelp" className="form-text text-muted">
          Lo usaremos para avisarte de tu pedido
        </small>
      </div>
      {loginPrompt && (
        <>
          <PasswordComp error={authContext.errors} password={password} />
          <button
            className="btn btn-dark btn-block mb-3"
            onClick={loginHandler}
            disabled={loading ? loading : false}
          >
            Iniciar Sesión
          </button>
        </>
      )}
    </div>
  );
};

const PasswordComp: React.FC<PasswordCompProps> = ({ password, error }) => (
  <React.Fragment>
    <div className="form-group">
      <label>Password</label>
      <input type="password" className="form-control" ref={password} />
    </div>{' '}
    {error && <div className="alert alert-waring">{error.message}</div>}
  </React.Fragment>
);

export default NoDataInfo;
