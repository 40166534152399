import React from "react";

import { Totals as TotalsType } from "../../../models/checkout_model";
import { UserType } from "../../../models/user_model";

type AtratoCompProps = {
  totals: TotalsType;
  orderId?: false | string;
  show: boolean;
};
const AtratoComp: React.FC<AtratoCompProps> = ({ totals, orderId, show }) => {
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
  const is_prod = process.env.REACT_APP_ENV === "production" ? true : false;

  return (
    <div
      className={show ? "" : "d-none"}
      data-key="bWF0aGFzYToxZDEwY2JlMzE4MzIyOTIxN2NiZDQyNDNmZmQ0ZDNmYmY5MmE4MTI2ZjJlMmFiOTQ1ZDkzNWZlMmQzZGQxMWNj"
      data-version="2"
      data-sandbox={is_prod ? "false" : "true"}
      id="higslide-div-atrato-info"
      // data-ordernumber={orderId}
      data-orderid={orderId}
      data-idcommerce={is_prod ? "3832302d31353032" : "3538392d333636"}
      data-price={totals.total}
      data-product="Accesorios"
      data-email={user.email}
      data-name={
        user.displayName ? user.displayName : `${user.name} ${user.lastname}`
      }
      data-phone={user.phone}
      data-plataform="other"
      data-type="pago"
      data-urlhost={
        is_prod
          ? "https://app.atratopago.com"
          : "https://sandbox.atratopago.com"
      }
    ></div>
  );
};

export default AtratoComp;
