import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import useProductTags from '../../hooks/useProductTags';
import { AuthContext } from '../../context/auth-context';
const items = [
  { icon: 'fas fa-shopping-cart', link: '/cart', txt: 'Carrito' },
  { icon: 'fas fa-map-marked', link: '/info/sucursales ', txt: 'Sucursales' },
  { icon: 'fas fa-wrench', link: '/info/servicios', txt: 'Servicios' },
  { icon: 'far fa-eye', link: 'visualiza', txt: 'Visualizador' },
  { icon: 'fas fa-certificate', link: '/promociones', txt: 'Promociones' },
  { icon: 'fas fa-search-location', link: '/#tienda', txt: 'Busca tu Tienda' },
  {
    icon: 'fas fa-hand-holding-heart',
    link: '/cuenta/recompensas',
    txt: 'Recompensas',
  },
];

type MobNavProps = { is_active: boolean; toggleMobNav: any };
type LevelProps = { toggleProducts: any; toggleMobNav: any };
type Level2Props = {
  toggleActive: any;
  show_products: boolean;
  toggleProducts: any;
  menu: any;
};
type Level3Props = {
  active_id: string | false;
  unActive: any;
  menu: any;
  toggleMobNav: any;
};

const MobNav: React.FC<MobNavProps> = ({ is_active, toggleMobNav }) => {
  const [active_id, setActive_id] = useState<false | string>(false);
  const [show_products, setShow_products] = useState(false);
  const { result: menu, loading, error } = useProductTags();
  const authContext = useContext(AuthContext);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const toggleProducts = () => {
    const toggle_products = !show_products;
    setShow_products(toggle_products);
  };
  const toggleActive = (ev: { currentTarget: HTMLElement }) => {
    const id = ev.currentTarget.dataset.id;
    if (id) {
      setActive_id(id);
    }
  };
  const unActive = () => {
    setActive_id(false);
  };
  return (
    <div className={`mob_nav d-md-none ${is_active && 'active'}`}>
      <div className="header">
        <i className="fas fa-user-circle"></i>
        {authContext.isAuth ? (
          <React.Fragment>
            <Link
              onClick={toggleMobNav}
              className="small"
              to="/cuenta/my-cuenta"
            >
              <span>
                {user.displayName}
                <small>Mi Cuenta</small>
              </span>
            </Link>
          </React.Fragment>
        ) : (
          <Link onClick={toggleMobNav} className="small" to="/login">
            Iniciar Sesión
          </Link>
        )}
      </div>
      <div className="body">
        <Level1 toggleProducts={toggleProducts} toggleMobNav={toggleMobNav} />
        <Level2
          toggleActive={toggleActive}
          show_products={show_products}
          toggleProducts={toggleProducts}
          menu={menu}
        />
        <Level3
          active_id={active_id}
          unActive={unActive}
          menu={active_id && menu ? menu[active_id] : null}
          toggleMobNav={toggleMobNav}
        />
      </div>
    </div>
  );
};

const Level1: React.FC<LevelProps> = ({ toggleProducts, toggleMobNav }) => (
  <div className="mob_nav_items">
    <button className="btn text-capitalize back_btn" onClick={toggleProducts}>
      <i className="fas fa-bath mr-2"></i> Productos
    </button>
    {items.map((item, key) => (
      <Link
        onClick={toggleMobNav}
        to={item.link}
        key={`nav_${key}`}
        className=""
      >
        {item.icon && <i className={`${item.icon} mr-2`}></i>}
        {item.txt}
      </Link>
    ))}
  </div>
);

const Level2: React.FC<Level2Props> = ({
  toggleActive,
  show_products,
  toggleProducts,
  menu,
}) => {
  return (
    <div className={`mob_nav_items mob_level ${show_products && 'active'}`}>
      <button className="btn btn-link  back_btn" onClick={toggleProducts}>
        <i className="fas fa-chevron-left mr-2"></i> Atrás
      </button>
      <ul>
        {menu &&
          Object.keys(menu).map((menu_item) => {
            return (
              <li key={menu_item}>
                <button
                  data-id={menu_item}
                  onClick={toggleActive}
                  className="btn btn-link text-capitalize d-flex justify-content-between align-items-center"
                >
                  {menu_item}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const Level3: React.FC<Level3Props> = ({
  active_id,
  unActive,
  menu,
  toggleMobNav,
}) => (
  <div className={`mob_nav_items mob_level ${active_id && 'active'}`}>
    <button className="btn btn-link back_btn" onClick={unActive}>
      <i className="fas fa-chevron-left mr-2"></i> Atrás
    </button>
    {active_id && (
      <div className="childs">
        <ul>
          <Link onClick={toggleMobNav} to={`/lista/${replaceWords(active_id)}`}>
            Ver todo en {active_id}
          </Link>
        </ul>
      </div>
    )}
    {active_id &&
      Object.keys(menu).map((menu_item) => {
        const level3 = menu[menu_item];
        return (
          <div className="childs" key={`menu_2_${menu_item}`}>
            <Link
              onClick={toggleMobNav}
              to={`/lista/${replaceWords(active_id)}/${replaceWords(
                menu_item
              )}`}
            >
              {menu_item}
            </Link>
            <ul>
              {level3.map((menu_3: string) => {
                return (
                  <li key={`meu_3_${menu_3}`}>
                    <Link
                      onClick={toggleMobNav}
                      to={`/lista/${replaceWords(active_id)}/${replaceWords(
                        menu_item
                      )}/${replaceWords(menu_3)}`}
                    >
                      {menu_3}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
  </div>
);

function replaceWords(word: string): string {
  word = word.split(' ').join('-');
  word = encodeURIComponent(word);
  return word.replace('Ba%C3%B1o', 'Bano');
}

export default MobNav;
