import React, { useState } from 'react';
import { formatNumber } from '../../../middleware/common-functions';

const Filters = ({ filters, updateFilters }) => (
  <div className={`filters`}>
    {Object.keys(filters).map((key) => {
      const { title, elements, type, selected, value } = filters[key];
      return (
        <Filter
          key={key}
          title={title}
          elements={elements}
          type={type}
          selected={selected}
          value={value}
          updateValue={updateFilters(key)}
        />
      );
    })}
  </div>
);

const Filter = ({ title, elements, type, selected, updateValue }) => {
  const [showMore, setShowMore] = useState(false);
  if (elements.length <= 1 && !selected.length) return null;
  return (
    <div className="filter mb-3">
      <h5> {title}</h5>
      <div className={`filter_container ${showMore && 'expanded'}`}>
        {
          <>
            {elements
              ? elements
                  .filter(
                    (elem) => !['Sin tamaño', 'Sin calidad'].includes(elem)
                  )
                  .map((value, i) => (
                    <Checkbox
                      key={value + i}
                      value={value}
                      isSelected={selected.includes(value)}
                      onClick={() => updateValue(value, type)}
                      type={type}
                    />
                  ))
              : '-'}
          </>
        }
      </div>
      {elements.length > 6 && (
        <button
          className="btn btn-link show_more_btn px-0 text-capitalize text-info"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Mostar Menos' : 'Mostar Más'}
        </button>
      )}
    </div>
  );
};

const Checkbox = ({ value, isSelected, onClick, type }) => (
  <button
    className="btn btn-block button-transparent text-left p-0 filter_item text-capitalize"
    onClick={onClick}
  >
    <i className={`${isSelected ? 'fas text-success' : 'far'} fa-square`}></i>{' '}
    {type === 'price' && '$'}
    {type === 'price' ? formatNumber(value) : value}
    {type === 'price' && ' MXN'}
  </button>
);

export default Filters;
