import React from 'react';

import Hero from './hero';
import Promotions from './promotions';
import NewestProducts from './newestProduct';
// import TopSales from "./topSales";
import { isMobile } from 'react-device-detect';
import { isDesign } from '../../../middleware/common-functions';

const Home = () => {
  const is_design = isDesign();
  return (
    <div className="first-container">
      <Hero filter="upper_banner" />
      {isMobile ? <Hero filter="mobile_img" /> : <Hero />}
      {is_design ? <Promotions is_design={is_design} /> : <Promotions />}
      {/* Best Sellers: */}
      {is_design ? (
        <NewestProducts is_design={is_design} />
      ) : (
        <NewestProducts />
      )}
      <Hero filter="lower_banner" />
      {/* <TopSales /> */}
    </div>
  );
};

export default Home;
