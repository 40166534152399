import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useMutation } from "@apollo/react-hooks";

import { db } from "../../../middleware/firebase";
import Loader from "../../common/loader";
import { AddressComponent } from "../checkout/addressComponent";
// import { SAVEADDRESS } from "./queries";

const Address = ({ address_id, router }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [address_data, setAddress_data] = useState({});
  const [loading, setLoading] = useState(true);
  // const [setAddressMutation, { loading: saving_address }] = useMutation(
  //   SAVEADDRESS
  // );
  let collection = db
    .collection("addresses")
    .doc(user.id)
    .collection("address");
  useEffect(() => {
    if (address_id) {
      collection = collection.doc(address_id);
      console.log("address_single.js:24 | collection", collection);
      getAddress();
    } else {
      setLoading(false);
    }
  }, []);

  const getAddress = async () => {
    const doc = await collection.get();
    setAddress_data(doc.data());
    setLoading(false);
  };

  const saveAddress = async () => {
    setLoading("saving");
    const currentAddress = { ...address_data };
    currentAddress.address = `${currentAddress.street} ${currentAddress.numext}, ${currentAddress.colonia} `;
    if (address_id) {
      delete currentAddress.api_id;
      delete currentAddress.distance;
      await collection.doc(address_id).set({ ...currentAddress });
    } else {
      await collection.add({ ...currentAddress });
      router.history.push(`/cuenta/direcciones`);
    }
    setLoading(false);
  };

  const deleteAddress = async () => {
    setLoading(true);
    await collection.doc(address_id).delete();
    router.history.push(`/cuenta/direcciones`);
    setLoading(false);
  };
  const handleAddressType = (ev) => {
    const type = ev.target.dataset.type;
    const value = ev.target.value;
    const currentDelivery = { ...address_data };
    currentDelivery[type] = value;
    if (currentDelivery.prev && type != "message") {
      currentDelivery.prev = false;
    }
    setAddress_data(currentDelivery);
  };

  return (
    <div className="my_address">
      <div className="card  material-shadows mb-3">
        <div className="preheader p-4 pb-2">
          <Link className="btn pl-0 text-capitalize " to="/cuenta/direcciones">
            <i className="fas fa-chevron-left"></i> Atrás
          </Link>
        </div>
        <div className="card-body ">
          {loading === true ? (
            <Loader />
          ) : (
            <AddressData
              data={address_data}
              handleAddressType={handleAddressType}
              saveAddress={saveAddress}
              deleteAddress={deleteAddress}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const AddressData = ({
  data,
  handleAddressType,
  saveAddress,
  loading,
  deleteAddress,
}) => (
  <div className="address_data">
    <AddressComponent deliveryData={data} handleDelivery={handleAddressType} />
    <div className="row justify-content-between">
      <div className="col-md-2">
        <button
          disabled={loading}
          className="btn btn-outline-danger  btn-block"
          onClick={deleteAddress}
        >
          Borrar
        </button>
      </div>
      <div className="col-md-6">
        <button
          disabled={loading}
          className="btn btn-success  btn-block"
          onClick={saveAddress}
        >
          {loading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </div>
  </div>
);

export default Address;
