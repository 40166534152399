import React from "react";

import Footer from "./footer";

const Main = (props) => (
  <React.Fragment>
    {props.children}
    <Footer />
  </React.Fragment>
);

export default Main;
