import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Account from "./account";
import Address from "./address";
import AddressSingle from "./address_single";
import Orders from "./orders";
import Quotes from "./quotes";
import Quote from "./quote";
import Cards from "./cards";
import Rewards from "./rewards";
import LegalInfo from "./legal";

const nav = [
  { name: "Mi Cuenta", link: "my-cuenta", icon: "far fa-user" },
  { name: "Mis pedidos", link: "pedidos", icon: "fas fa-box-open" },
  { name: "Mis cotizaciones", link: "cotizaciones", icon: "fas fa-boxes" },
  { name: "Mis Tarjetas", link: "tarjetas", icon: "far fa-credit-card" },
  {
    name: "Mis Recompensas",
    link: "recompensas",
    icon: "fas fa-hand-holding-heart",
  },
  {
    name: "Mis Direcciones",
    link: "direcciones",
    icon: "fas fa-map-marker-alt",
  },
  {
    name: "Facturación",
    link: "facturas",
    icon: "fas fa-money-check-alt",
  },
];

const AccountPage = (props) => {
  const { section, item_id } = useParams();
  const [sectionTitle, setSectionTitle] = useState("Mi Cuenta");
  const [sectionRender, setSectionRender] = useState(<Account />);
  useEffect(() => {
    console.log("index.js:34 | props", props);
    rederSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);
  const rederSection = () => {
    switch (section) {
      case "direcciones":
        setSectionTitle("Mis Direcciones");
        setSectionRender(<Address router={props} />);
        break;
      case "direccion":
        setSectionTitle("Mis Direcciones");
        setSectionRender(<AddressSingle address_id={item_id} router={props} />);
        break;
      case "pedidos":
        setSectionTitle("Mis Pedidos");
        setSectionRender(<Orders router={props} />);
        break;
      case "cotizaciones":
        setSectionTitle("Mis Cotizaciones Guardadas");
        setSectionRender(<Quotes router={props} />);
        break;
      case "cotizacion":
        setSectionTitle("Mis Cotizaciones Guardadas");
        setSectionRender(<Quote router={props} order_id={item_id} />);
        break;
      case "tarjetas":
        setSectionTitle("Mis Tarjetas");
        setSectionRender(<Cards router={props} />);
        break;
      case "recompensas":
        setSectionTitle("Mis Recompensas");
        setSectionRender(<Rewards />);
        break;
      case "facturas":
        setSectionTitle("Mis Recompensas");
        setSectionRender(<LegalInfo />);
        break;
      default:
        setSectionTitle("Mi Cuenta");
        setSectionRender(<Account />);
        break;
    }
  };

  return (
    <div className="first-container last-container bg_gray min-vh-80 account_page">
      <div className="container">
        <h1 className=" mt-4 pt-4">{sectionTitle}</h1>
        <div className="row justify-content-between mt-4">
          <div className="col-md-3 mb-3">
            <div className="list-group  material-shadows ">
              {nav.map((item, key) => (
                <Link
                  key={`my_account_${key}`}
                  to={`/cuenta/${item.link}`}
                  className={`list-group-item list-group-item-action ${
                    item.link === section && "active"
                  }`}
                >
                  <i className={` mr-2 fa-fw ${item.icon}`}></i>
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-md-9">{sectionRender}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
