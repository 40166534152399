import { Helmet } from "react-helmet";
import React from "react";

const production = process.env.REACT_APP_FIRST_DATA_PROD
  ? process.env.REACT_APP_FIRST_DATA_PROD === "false"
    ? false
    : false
  : false;
const vesta_accountName = "84hJFatx9kuwBb5zALHqWw==";

const vesta_token_url = production
  ? "https://vsafe1token.ecustomerpayments.com/GatewayV4ProxyJSON/Service"
  : "https://vsafesandbox.ecustomersupport.com/GatewayV4ProxyJSON/Service";

export const tokenize = (card_number) => {
  window.vestatoken.init({
    ServiceURL: vesta_token_url,
    AccountName: vesta_accountName,
  });
  return new Promise((res) => {
    let response = false;
    window.vestatoken.getcreditcardtoken({
      ChargeAccountNumber: parseInt(card_number.split(" ").join("")),
      onSuccess: (data) => {
        // make use of 'data.ChargeAccountNumberToken' (String), 'data.PaymentDeviceLast4' (String) and 'data.PaymentDeviceTypeCD' (String)
        return res(data);
      },

      onFailed: (failure) => {
        // make use of `failure` (String)
        console.log("vesta.js:35 | failure", failure);
        return res(false);
      },

      onInvalidInput: (failure) => {
        // make use of `failure` (String)
        console.log("vesta.js:40 | failure", failure);
        return res(false);
      },
    });
  });
};

const VestaProvider = ({ fingerprint }) => {
  //console.log("vesta.js:5 | fingerprint", fingerprint);
  return (
    <div
      style={{
        height: 0,
        overflow: "hidden",
      }}
    >
      <p
        style={{
          background: `url(https://fp.ecustomerpayments.com/DF/fp/clear.png?org_id=${fingerprint.OrgID}&session_id=${fingerprint.WebSessionID}&m=1)`,
        }}
      ></p>
      <img
        src={`https://fp.ecustomerpayments.com/DF/fp/clear.png?org_id=${fingerprint.OrgID}&session_id=${fingerprint.WebSessionID}&m=2`}
        alt=""
      />

      <Helmet>
        <script
          src={`https://fp.ecustomerpayments.com/DF/fp/check.js?org_id=${fingerprint.OrgID}&session_id=${fingerprint.WebSessionID}`}
          type="text/javascript"
        ></script>
        <script
          src={`${process.env.REACT_APP_VESTA_DATACOLLECTOR_URL}?AccountName=${process.env.REACT_APP_VESTA_ACCOUNT}&WebSessionID=${fingerprint.WebSessionID}`}
          async
          type="text/javascript"
        ></script>
      </Helmet>

      <object
        data={`https://fp.ecustomerpayments.com/DF/fp/fp.swf?org_id=${fingerprint.OrgID}&session_id=${fingerprint.WebSessionID}`}
        type="application/x-shockwave-flash"
        width="1"
        height="1"
        id="obj_id"
      >
        <param
          value={`https://fp.ecustomerpayments.com/DF/fp/fp.swf?org_id=${fingerprint.OrgID}&session_id=${fingerprint.WebSessionID}`}
          name="movie"
        />
      </object>
    </div>
  );
};

export default VestaProvider;
