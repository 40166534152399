import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";

const benefits = [
  "Verifica el estado de tus compras",
  "Recibe promociones muy atractivas",
  "Compra con menos clicks",
];

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(null);
  const email = useRef(false);
  const password = useRef(false);
  const loginHandler = () => {
    if (!email.current.value || !password.current.value) return false;
    setLoading(true);
    authContext.loginFun(email.current.value, password.current.value);
  };

  useEffect(() => {
    if (authContext && authContext.error) {
      setLoading(false);
    }
  }, [authContext]);

  return (
    <div className="container first-container last-container ">
      <div className="row justify-content-between my-5 ">
        <div className="p-4 col-md-5">
          <h2 className="underlined my-4 py-2">Login</h2>
          <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" ref={email} />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" ref={password} />
          </div>
          {authContext.error && (
            <div className="alert alert-waring">
              {authContext.error.message}
            </div>
          )}
          <button
            className="btn btn-dark btn-block mb-3"
            onClick={loginHandler}
            disabled={loading}
          >
            Iniciar Sesión
          </button>
          <small>
            Tienes problemas para entrar a tu cuenta? <br />
            <Link to="/recuperar">Recupera tu Password</Link>
          </small>
        </div>
        <div className="p-4 col-md-5 border-left">
          <h2 className="underlined my-4 py-2 opacity_0">Login</h2>
          <h4>¿No tienes cuenta?</h4>
          <p>
            Crea tu cuenta gratis y disfruta de los beneficios de comprar online
            en Mathasa
          </p>
          <Link
            to="/signup"
            className="btn btn-outline-secondary btn-block text-center"
          >
            Crear cuenta
          </Link>
          <ul className="mt-4">
            {benefits.map((item, key) => (
              <li key={`benefits_${key}`}>
                <i className="fas fa-check text-success mr-2"></i> {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
