import React from "react";
import EmptyJumbotron from "../../common/emptyJumbotron";
import { formatNumber } from "../../../middleware/common-functions";
import { useQuery } from "@apollo/react-hooks";
import { GETCLIENT } from "./queries";
const Rewards = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: user_db } = useQuery(GETCLIENT, {
    variables: { email: user.email },
  });
  console.log("rewards.js:10 | user_db", user_db);
  return (
    <div className="my_address">
      <div className="card  material-shadows mb-3">
        <div className="card-body">
          {!user_db || !user_db.xvcm || !user_db.xvcm.sldrec ? (
            <EmptyJumbotron
              icon="fas fa-hand-holding-heart"
              heading="No Tienes Recompensas Aún"
              text="Sigue haciendo tus compras en Mathasa.mx para generar recompensas"
            />
          ) : (
            <EmptyJumbotron
              icon="fas fa-hand-holding-heart"
              heading="Tienes"
              sub_heading={`$${formatNumber(user_db.xvcm.sldrec)}`}
              text="En saldo para hacer tus compras. Sigue haciendo tus compras en Mathasa.mx para generar más recompensas"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Rewards;
